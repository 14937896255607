import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect, ResolveThunks } from 'react-redux';
import qs from 'querystring';

import { RootState } from '~/redux/store';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import './Comments.css';

import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spinner from '~/components/Spinner/Spinner';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';
import {
  fetchCommentsRequest,
  fetchCommentThreadRequest,
  fetchCommentsWithTimeFilterRequest,
  fetchGatheringsRequest,
  fetchGatheringComments
} from '../../api/building';
import { fetchGatheringsSuccess } from '~/reducers/building';
import { Comment } from '~/types/comment';
import { ReactComponent as Camera } from '~/utils/images/camera.svg';

import moment from 'moment';
import { useOnClickOutside } from '~/helpers/helpers';
import GenericModal from '~/components/GenericModal/GenericModal';
import {
  createGroupCommentRequest,
  createGroupEventCommentRequest,
  sendEmailRequest,
  updateGroupComment,
  updateGroupEventComment,
  moveCommentToMarketplace
} from '../../api/comment';
import {
  defaultTimeFilter,
  possibleFilters,
  removalReasonsForHideOrDeleteComment
} from '~/helpers/constants';
import ImageDropzone from '~/components/ImageDropzone/ImageDropzone';
import { Roles } from '~/enums/Roles';
import CheckboxList from '~/components/CheckboxList/CheckboxList';
import _ from 'lodash';
import { CommentTypes } from '~/enums/CommentTypes';
import CommentSectionFilters from '~/components/CommentSectionFilters/CommentSectionFilters';
import NoComments from '~/components/NoComments/NoComments';
import CommentBox from '~/components/CommentBox/CommentBox';
import { fetchGroupComments, fetchGroups } from '~/api/groups';
import { toast } from 'react-toastify';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { useSearchQuery } from '~/hooks/useSearchQuery';
import { Building } from '~/types/building';
import gathering from '~/reducers/gathering';
import { GatheringType } from '~/types/gathering';

interface DispatchProps {
  fetchGatheringsSuccess: typeof fetchGatheringsSuccess;
}

export enum CommentFilterType {
  BUILDING = 'BUILDING',
  GATHERING = 'GATHERING',
  GROUP = 'GROUP'
}

type Props = ResolveThunks<DispatchProps> & ReturnType<typeof mapStateToProps>;
const Comments = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [selectedCommentId, setSelectedCommentId] = useState('');
  const [selectedComment, setSelectedComment] = useState({});
  const [newMessage, setNewMessage] = useState('');
  const [newMessageFromModal, setNewMessageFromModal] = useState('');
  const [newReplyMessage, setNewReplyMessage] = useState('');
  const [selectedReason, setSelectedReason] = useState('');
  const [replyingParent, setReplyingParent] = useState('');
  const [filter, setFilter] = useState(defaultTimeFilter);
  const [singleCommentThread, setSingleCommentThread] = useState({} as Comment);

  // modals
  const [showModal, setShowModal] = useState('');
  const [showReplayModal, setShowReplayModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showThreadModal, setShowThreadModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  //notify resident
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');

  const [image, setImage] = useState<File | string>('');

  const [commentFilterType, setCommentFilterType] = useState<CommentFilterType>(
    CommentFilterType.BUILDING
  );
  const [selectedGatheringId, setSelectedGatheringId] = useState('');
  const [gatheringDate, setSelectedGatheringDate] = useState('');
  const [selectedGroupId, setSelectedGroupId] = useState('');

  const [showGroups, setShowGroups] = useState(true);
  const [showGatherings, setShowGatherings] = useState(true);
  const [groups, setGroups] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [building, setbuilding] = useState<Building>();
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setSelectedCommentId('');
  });

  const { buildingId } = useParams<{ buildingId: string }>();
  const history = useHistory();
  const query = useSearchQuery();

  const selectedBuilding = getBuildingFromStore(buildingId);
  const queryClient = useQueryClient();
  const [upcomingGatherings, setupcomingGatherings] = useState<GatheringType[]>(
    []
  );
  const [gatheringIds, setgatheringIds] = useState<string[]>([]);
  const [pastGatherings, setpastGatherings] = useState<GatheringType[]>([]);

  const containerRef = useBottomScrollListener(
    () =>
      !filter &&
      !actionLoading &&
      commentFilterType === CommentFilterType.BUILDING &&
      chatCommentsQuery.fetchNextPage()
  );

  const chatCommentsQuery = useInfiniteQuery(
    ['chat-comments', buildingId],
    async ({ pageParam = 0 }) => {
      const res = await fetchCommentsRequest(buildingId, pageParam + 1);
      return res.data;
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length ? pages.length : undefined
    }
  );

  const timeFilteredChatCommentsQuery = useQuery(
    ['chat-comments', buildingId, filter],
    async () => {
      const res = await fetchCommentsWithTimeFilterRequest(buildingId, filter);
      return res.data;
    }
  );

  const selectedGroupCommentsQuery = useQuery(
    ['group-comments', buildingId, selectedGroupId],
    async () => {
      const res = await fetchGroupComments(selectedGroupId);
      return res.data?.comments ?? [];
    },
    {
      enabled: !!selectedGroupId
    }
  );

  const selectedGatheringCommentsQuery = useQuery(
    ['gathering-comments', buildingId, selectedGatheringId, gatheringDate],
    async () => {
      const res = await fetchGatheringComments(
        selectedGatheringId,
        gatheringDate
      );
      return res.data;
    },
    {
      enabled: !!selectedGatheringId
    }
  );

  const updateGroupCommentMutation = useMutation(updateGroupComment, {
    onSuccess: () => {
      setSelectedComment({});
      setSelectedCommentId('');
      if (commentFilterType === CommentFilterType.BUILDING) {
        queryClient.invalidateQueries(['chat-comments', buildingId]);
      } else if (commentFilterType === CommentFilterType.GROUP) {
        queryClient.invalidateQueries([
          'group-comments',
          buildingId,
          selectedGroupId
        ]);
      }
    }
  });

  const updateGatheringCommentMutation = useMutation(updateGroupEventComment, {
    onSuccess: () => {
      setSelectedComment({});
      setSelectedCommentId('');
      queryClient.invalidateQueries([
        'gathering-comments',
        buildingId,
        selectedGatheringId
      ]);
    }
  });

  const moveCommentToMarketplaceMutation = useMutation(
    moveCommentToMarketplace,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['chat-comments', buildingId]);
      }
    }
  );

  const createGroupCommentMutation = useMutation(createGroupCommentRequest, {
    onSuccess: () => {
      if (commentFilterType === CommentFilterType.BUILDING) {
        queryClient.invalidateQueries(['chat-comments', buildingId]);
      } else if (commentFilterType === CommentFilterType.GROUP) {
        queryClient.invalidateQueries([
          'group-comments',
          buildingId,
          selectedGroupId
        ]);
      }
    }
  });

  const createGatheringCommentMutation = useMutation(
    createGroupEventCommentRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'gathering-comments',
          buildingId,
          selectedGatheringId
        ]);
      }
    }
  );

  const { currentUserRole, fetchGatheringsSuccess } = props;

  const doFetchGroups = async () => {
    const res = await fetchGroups([selectedBuilding.uuid]);
    setGroups(res.data);
  };

  const readFiltersFromQueryParams = () => {
    if (query.groupId) {
      setSelectedGroupId(query?.groupId as string);
      setFilter(0);
      setCommentFilterType(CommentFilterType.GROUP);
    } else if (query.gatheringId) {
      setSelectedGatheringId(query.gatheringId as string);
      setSelectedGatheringDate(query.gatheringDate as string);
      setSelectedGroupId('');
      setFilter(0);
    }
  };

  useEffect(() => {
    readFiltersFromQueryParams();

    doFetchGroups();
  }, []);

  const creatorEmail =
    currentUserRole === Roles.Superadmin || currentUserRole === Roles.Vcm
      ? selectedBuilding.VCMCommenter
      : selectedBuilding.PMCommenter;

  const getGatherings = async () => {
    const gatheringRes = await fetchGatheringsRequest(
      selectedBuilding.uuid,
      'ALL',
      'scheduledStartTime ASC'
    );

    const { data } = gatheringRes;

    const upcoming: GatheringType[] = [],
      past: GatheringType[] = [];

    data.forEach((gathering: GatheringType) => {
      const scheduledStartTime = moment(gathering.scheduledStartTime);
      if (scheduledStartTime.isAfter(moment())) {
        upcoming.push(gathering);
      } else if (scheduledStartTime.isAfter(moment().subtract(1, 'month'))) {
        past.push(gathering);
      }
    });
    setupcomingGatherings(upcoming.slice(0, 20));
    setpastGatherings(past);
    setgatheringIds([...upcoming, ...past].map((gathering) => gathering.uuid));
    setLoading(false);
  };

  useEffect(() => {
    getGatherings();
  }, []);

  const sendEmail = () => {
    if (email && message && subject) {
      sendEmailRequest(email, subject, message);
    }

    setShowEmailModal(false);
    setSelectedComment({});
    setSubject('');
    setMessage('');
    setEmail('');
  };

  const handleCommentFilterChange = (
    type: CommentFilterType,
    id: string,
    interestedDate?: string
  ) => {
    setCommentFilterType(type);
    if (type === CommentFilterType.BUILDING) {
      setFilter(12);
      setSelectedGatheringId('');
      setSelectedGroupId('');
    } else if (type === CommentFilterType.GATHERING) {
      setSelectedGatheringId(id);
      setSelectedGatheringDate(interestedDate ?? '');
      history.push(
        `/building/${buildingId}/comments?gatheringId=${id}${
          interestedDate ? `&gatheringDate=${interestedDate}` : ''
        }`
      );
      setSelectedGroupId('');
      setFilter(0);
    } else if (type === CommentFilterType.GROUP) {
      setSelectedGroupId(id);
      history.push(`/building/${buildingId}/comments?groupId=${id}`);
      setSelectedGatheringId('');
      setSelectedGatheringDate('');
      setFilter(0);
    }
  };

  const createComment = (reply: boolean, modal: boolean) => {
    let params = modal
      ? {
          buildingId,
          contents: reply ? newReplyMessage : newMessageFromModal,
          parent: reply ? replyingParent : undefined,
          imageFile: image,
          creatorEmail
        }
      : {
          buildingId,
          contents: reply ? newReplyMessage : newMessage,
          parent: reply ? replyingParent : undefined,
          creatorEmail
        };

    const dataToSend = new FormData();

    if (buildingId) dataToSend.append('building', buildingId);
    if (params.contents) dataToSend.append('contents', params.contents.trim());
    if (params.parent) dataToSend.append('parent', params.parent);
    if (creatorEmail) dataToSend.append('creatorEmail', creatorEmail);

    if (
      commentFilterType === CommentFilterType.GATHERING &&
      selectedGatheringId &&
      gatheringIds.includes(selectedGatheringId)
    ) {
      if (gatheringDate && moment(gatheringDate).isValid()) {
        dataToSend.append('interestedDate', gatheringDate);
      }
      dataToSend.append('groupEvent', selectedGatheringId);
      if (params.imageFile) {
        dataToSend.append('images', params.imageFile);
      }
      createGatheringCommentMutation.mutate(dataToSend);
    } else if (
      commentFilterType === CommentFilterType.GROUP &&
      selectedGroupId
    ) {
      dataToSend.append('group', selectedGroupId);
      if (params.imageFile) {
        dataToSend.append('images', params.imageFile);
      }
      createGroupCommentMutation.mutate(dataToSend);
    } else if (commentFilterType === CommentFilterType.BUILDING) {
      dataToSend.append('group', 'null');
      if (params.imageFile) {
        dataToSend.append('images', params.imageFile);
      }
      createGroupCommentMutation.mutate(dataToSend);
    }
    setNewMessage('');
    setShowReplayModal(false);
    setShowImageModal(false);
    setReplyingParent('');
    setNewReplyMessage('');
    setNewMessageFromModal('');
    setImage('');
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setImage(acceptedFiles[0]);
  };

  const onReplyClick = (comment: Comment) => {
    setShowThreadModal(false);
    setShowReplayModal(true);
    if (comment.parent) {
      setReplyingParent(comment.parent.uuid);
    } else {
      setReplyingParent(comment.uuid);
    }
  };

  const onDeleteClick = (comment: Comment) => {
    let params = {
      ...comment,
      removed: false,
      removedBy: '',
      removalReason: ''
    };
    if (comment.removed) {
      if (gatheringIds.includes(selectedGatheringId)) {
        // undelete
        updateGatheringCommentMutation.mutate(params);
      } else {
        // undelete
        updateGroupCommentMutation.mutate(params);
      }
    } else {
      setShowModal('DELETE');
      setSelectedComment(comment);
    }
  };

  const notifyResident = (comment: Comment) => {
    setShowEmailModal(true);
    setEmail(comment.user.email);
    setMessage(comment.contents ? `"${comment.contents}"` : '');
  };

  const markGreatContent = (comment: Comment) => {
    const params = {
      ...comment,
      greatContent: !comment.greatContent,
      removedBy: '',
      removalReason: ''
    };

    if (commentFilterType === CommentFilterType.GATHERING) {
      updateGatheringCommentMutation.mutate(params);
    } else {
      updateGroupCommentMutation.mutate(params);
    }
  };
  const moveToMarketplace = (comment: Comment) => {
    const params = {
      ...comment
    };

    moveCommentToMarketplaceMutation.mutate(params);
    // updateGroupCommentMutation.mutate(params)

    toast('The comment has been moved to the Marketplace.');
  };
  const onHideCommentClick = (comment: Comment) => {
    let params;
    if (comment.hidden) {
      params = {
        ...comment,
        hidden: false,
        removedBy: '',
        removalReason: '',
        passedmanualmoderation: true
      };

      if (gatheringIds.includes(selectedGatheringId)) {
        updateGatheringCommentMutation.mutate(params);
      } else {
        // unhide
        updateGroupCommentMutation.mutate(params);
      }
    } else {
      setShowModal('HIDE');
      setSelectedComment(comment);
    }
  };

  const onPinCommentClick = (comment: Comment) => {
    let params;
    let pinCommentSwitch = comment.pinComment ? false : true;
    let toastMessage = comment.pinComment
      ? 'The Comment has been unpinned.'
      : 'The Comment has been pinned.';

    params = {
      uuid: comment.uuid,
      pinComment: pinCommentSwitch
    };

    updateGroupCommentMutation.mutate(params);
    toast(toastMessage);
  };

  const modalAction = () => {
    const isGroupEventComment = gatheringIds.includes(selectedGatheringId);

    if (showModal === 'HIDE') {
      let params = {
        ...selectedComment,
        hidden: true,
        hiddenBy: 'ADMIN',
        removedBy: '',
        removalReason: '',
        removed: false,
        passedmanualmoderation: true
      };
      if (isGroupEventComment) {
        updateGatheringCommentMutation.mutate(params);
      } else {
        updateGroupCommentMutation.mutate(params);
      }
    } else if (showModal === 'DELETE') {
      let params = {
        ...selectedComment,
        hidden: false,
        removedBy: 'ADMIN',
        removalReason: selectedReason,
        removed: true
      };
      if (isGroupEventComment) {
        updateGatheringCommentMutation.mutate(params);
      } else {
        updateGroupCommentMutation.mutate(params);
      }
    }
    setShowModal('');
    setShowEmailModal(true);
    //@ts-ignore
    setEmail(selectedComment.user.email);
    setMessage(
      //@ts-ignore
      selectedComment && selectedComment.contents
        ? //@ts-ignore
          `"${selectedComment.contents}"`
        : ''
    );
    setSelectedReason('');
  };

  const renderComments = () => {
    let commentsToRender = [];
    if (commentFilterType === CommentFilterType.BUILDING) {
      if (!filter) {
        if (!chatCommentsQuery.isSuccess) {
          return <Spinner />;
        }

        if (!chatCommentsQuery.data.pages.length) {
          return <NoComments />;
        }

        commentsToRender = chatCommentsQuery.data.pages.flat();
      } else {
        if (timeFilteredChatCommentsQuery.isLoading) {
          return <Spinner />;
        }
        if (!timeFilteredChatCommentsQuery.data.length) {
          return <NoComments />;
        }
        commentsToRender = timeFilteredChatCommentsQuery.data;
      }
    } else if (commentFilterType === CommentFilterType.GROUP) {
      if (!selectedGroupCommentsQuery.isSuccess) {
        return <Spinner />;
      }

      if (!selectedGroupCommentsQuery.data.length) {
        return <NoComments />;
      }

      commentsToRender = selectedGroupCommentsQuery.data;
    } else {
      if (selectedGatheringCommentsQuery.isLoading) {
        return <Spinner />;
      }
      if (!selectedGatheringCommentsQuery.data.length) {
        return <NoComments />;
      }
      commentsToRender = selectedGatheringCommentsQuery.data;
    }

    return commentsToRender
      .filter((c: Comment) => {
        if (possibleFilters.includes(filter))
          return (
            moment.duration(moment(new Date()).diff(c.createdAt)).asHours() <
            filter
          );
        else return true;
      })
      .map((comment: Comment) => renderParentsAndChildren(comment, false));
  };

  const renderCommentInput = () => {
    if (filter !== 0 || !creatorEmail) {
      return null;
    }

    return (
      <div className='comments-bottom-fields margin-bottom-32'>
        <div className='input-container'>
          <textarea
            id='message'
            name='message'
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder={'Type a new message'}
            className='new-comment-input'
            maxLength={2000}
          />
          <div onClick={() => setShowImageModal(true)}>
            <Camera className='camera margin-left-8 margin-right-16' />
          </div>
        </div>
        <button
          disabled={!newMessage}
          className='btn-standard btn-primary margin-left-16 weight-500'
          type='submit'
          onClick={() => createComment(false, false)}
        >
          Send
        </button>
      </div>
    );
  };

  const onOpenThreadClick = (parentComment: Comment) => {
    setModalLoading(true);
    fetchCommentThreadRequest(
      //@ts-ignore
      parentComment.parent?.uuid
        ? //@ts-ignore
          parentComment.parent.uuid
        : parentComment.uuid
    ).then((res) => {
      setSingleCommentThread(res.data[0]);
      setModalLoading(false);
    });
    setShowThreadModal(true);
  };
  const renderParentsAndChildren = (
    parentComment: Comment,
    isThread: boolean
  ) => {
    let children;
    if (
      parentComment.type === CommentTypes.NEW_USERS &&
      currentUserRole === Roles.Pm
    )
      return;
    if (parentComment.children && parentComment.children.length) {
      children = parentComment.children.map((child, index) => (
        <CommentBox
          comment={child}
          index={index}
          parentComment={parentComment}
          filter={filter}
          isThread={isThread}
          currentUserRole={currentUserRole}
          selectedCommentId={selectedCommentId}
          setSelectedCommentId={setSelectedCommentId}
          onReplyClick={onReplyClick}
          onDeleteClick={onDeleteClick}
          notifyResident={notifyResident}
          setShowThreadModal={setShowThreadModal}
          hideComment={onHideCommentClick}
          pinComment={onPinCommentClick}
          onOpenThreadClick={onOpenThreadClick}
          key={child.uuid}
          markGreatContent={markGreatContent}
          moveToMarketplace={moveToMarketplace}
          commentType={commentFilterType}
        />
      ));
    }
    return (
      <CommentBox
        comment={parentComment}
        filter={filter}
        isThread={isThread}
        currentUserRole={currentUserRole}
        selectedCommentId={selectedCommentId}
        setSelectedCommentId={setSelectedCommentId}
        onReplyClick={onReplyClick}
        onDeleteClick={onDeleteClick}
        notifyResident={notifyResident}
        setShowThreadModal={setShowThreadModal}
        hideComment={onHideCommentClick}
        pinComment={onPinCommentClick}
        onOpenThreadClick={onOpenThreadClick}
        children={children}
        key={parentComment.uuid}
        markGreatContent={markGreatContent}
        moveToMarketplace={moveToMarketplace}
        commentType={commentFilterType}
        preventIndent={
          commentFilterType === CommentFilterType.BUILDING && !!filter
        }
      />
    );
  };

  return (
    <div className='content-container'>
      {loading && <Spinner />}
      {!loading && (
        <div>
          <Breadcrumbs />
          <p className='h1-title'>
            Chat Tab in <strong>{selectedBuilding.name}</strong>
          </p>
          <div className='contentContainer'>
            <div className='timeFilterCommentsContainer margin-right-24'>
              {commentFilterType === CommentFilterType.BUILDING && (
                <div className='filters'>
                  {possibleFilters.map((f) => (
                    <p
                      key={f}
                      className={
                        filter === f
                          ? 'single-filter single-filter-selected margin-right-16'
                          : 'single-filter margin-right-16'
                      }
                      onClick={() => {
                        setFilter(f);
                      }}
                    >
                      {f === 168 ? '7 Days' : `${f} Hours`}
                    </p>
                  ))}
                  <p
                    className={
                      !filter
                        ? 'single-filter single-filter-selected margin-right-16'
                        : 'single-filter margin-right-16'
                    }
                    onClick={() => {
                      setFilter(0);
                    }}
                  >
                    All Comments
                  </p>
                </div>
              )}
              <div
                // @ts-ignore
                ref={containerRef}
                className='comments-container margin-top-4 margin-bottom-8'
              >
                {renderComments()}
              </div>
              {renderCommentInput()}
            </div>
            <CommentSectionFilters
              selectedBuilding={selectedBuilding}
              upcomingGatherings={upcomingGatherings}
              pastGatherings={pastGatherings}
              selectFilter={handleCommentFilterChange}
              selectedGatheringId={selectedGatheringId}
              gatheringDate={gatheringDate}
              userRole={currentUserRole}
              selectedGroupId={selectedGroupId}
              showGatherings={showGatherings}
              handleGatheringFilterClick={() =>
                setShowGatherings((prevState) => !prevState)
              }
              showGroups={showGroups}
              handleGroupFilterClick={() =>
                setShowGroups((prevState) => !prevState)
              }
              groups={groups}
            />
          </div>
        </div>
      )}
      <GenericModal
        showModal={!!showModal}
        title={showModal === 'HIDE' ? 'Hide comment?' : 'Delete comment?'}
        content={
          <div>
            <div>
              <p>Choose the reason of the moderation of the comment.</p>
            </div>
            <CheckboxList
              options={removalReasonsForHideOrDeleteComment}
              selectedOption={selectedReason}
              setSelectedOption={setSelectedReason}
            />
          </div>
        }
        actionText={showModal === 'HIDE' ? 'Hide comment' : 'Delete comment'}
        onChange={() => {
          setShowModal('');
          setSelectedComment({});
          setSelectedReason('');
        }}
        actionButtonDisabled={!selectedReason}
        actionFunction={modalAction}
      />
      <GenericModal
        showModal={showReplayModal}
        title={'Reply post'}
        content={
          <div className='txt-area-container'>
            <textarea
              id='reply'
              name='reply'
              value={newReplyMessage}
              onChange={(e) => setNewReplyMessage(e.target.value)}
              placeholder={'Reply here...'}
              className='reply-input'
            />
            <div
              onClick={() => {
                setShowReplayModal(false);
                setShowImageModal(true);
              }}
            >
              <Camera className='margin-top-16 margin-right-16' />
            </div>
          </div>
        }
        actionText={'Save'}
        onChange={() => {
          setShowReplayModal(false);
          setNewReplyMessage('');
          setReplyingParent('');
        }}
        actionButtonDisabled={!newReplyMessage}
        actionFunction={async () => createComment(true, false)}
      />
      <GenericModal
        showModal={showImageModal}
        title={'Upload Photo'}
        content={
          <div>
            <ImageDropzone image={image} handleDrop={handleDrop} />
            <textarea
              id='message'
              name='message'
              value={replyingParent ? newReplyMessage : newMessageFromModal}
              onChange={(e) =>
                replyingParent
                  ? setNewReplyMessage(e.target.value)
                  : setNewMessageFromModal(e.target.value)
              }
              placeholder={'Message...'}
              className='new-comment-input-modal'
              maxLength={500}
            />
          </div>
        }
        actionText={'Submit'}
        onChange={() => {
          setShowImageModal(false);
          setImage('');
          setNewMessageFromModal('');
          setReplyingParent('');
          setNewReplyMessage('');
        }}
        actionButtonDisabled={
          !image && !newMessageFromModal && !newReplyMessage
        }
        actionFunction={async () => createComment(!!replyingParent, true)}
      />
      <GenericModal
        showModal={showEmailModal}
        title={'Notify the Resident'}
        content={
          <div>
            <p className='margin-bottom-16'>
              Notice resident about hidden/deleted comment!
            </p>
            <label>Subject</label>
            <input
              className='email-txt-area'
              type='text'
              id='subject'
              name='subject'
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <label>Message</label>
            <textarea
              id='email'
              name='email'
              className='email-txt-area'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        }
        actionFunction={() => sendEmail()}
        actionText={'Send Message'}
        cancelOverride={'Skip this Step'}
        onChange={() => {
          setShowEmailModal(false);
          setSubject('');
          setEmail('');
          setMessage('');
        }}
        actionButtonDisabled={!email || !message || !subject}
      />
      <GenericModal
        showModal={showThreadModal}
        title={''}
        content={
          modalLoading ? (
            <Spinner />
          ) : (
            <div className='single-thread-container'>
              {!_.isEmpty(singleCommentThread) &&
                renderParentsAndChildren(singleCommentThread, true)}
            </div>
          )
        }
        actionText={''}
        actionFunction={() => {}}
        onChange={() => {
          setShowThreadModal(false);
          setSingleCommentThread({} as Comment);
        }}
        hideActionButton
      />
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    currentUserRole: state.auth.userRole,
    gatherings: state.building.gatherings
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    fetchGatheringsSuccess
  };
}

export default connect(mapStateToProps, mapDispatchToProps())(Comments);
