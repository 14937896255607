import React from 'react';
import { useParams } from 'react-router-dom';
import CobuTable from '~/components/CobuTable/CobuTable';
import Spinner from '~/components/Spinner/Spinner';
import useCommunityLeads from '~/hooks/building/referral/useCommunityLeads';
import { ICommunityLead } from '~/api/referral';
import { ReactComponent as Community } from '~/utils/images/community.svg';
import './ReferralLeads.css';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import { CellProps } from 'react-table';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import useGetBuildingDetails from '~/hooks/building/useGetBuildingDetails';
import { Building } from '~/types/building';

export const ReferralLeads = () => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const { loading, referralLeads } = useCommunityLeads(buildingId);
  
  const buildingDetails = useGetBuildingDetails(buildingId);

  if (loading) {
    return <Spinner />;
  }

  if (referralLeads && referralLeads.length <= 0) {
    return (
      <div className='container'>
        <Breadcrumbs />
        <div className='no-referral-container'>
          <Community />
          <h2>No Referral found!</h2>
        </div>
      </div>
    );
  }

  const buildingTimezone = (buildingDetails as unknown as Partial<Building>)
    ?.timezone;

  const referralLeadColumns = [
    {
      Header: 'REFERRED BY',
      accessor: 'referredBy'
    },
    {
      Header: 'REFERRER EMAIL',
      accessor: 'referrerEmail'
    },
    {
      Header: 'UNIT',
      accessor: 'roomNumber'
    },
    {
      Header: 'CREATED TIME',
      accessor: 'created',
      Cell: (cell: CellProps<ICommunityLead>) => (
        <div>
          {momentTimezone
            .tz(cell.row.original.createdAt, buildingTimezone ?? '')
            .format('hh:mm A')}
        </div>
      )
    },
    {
      Header: 'CREATED DATE',
      accessor: 'createdAt',
      Cell: (cell: CellProps<ICommunityLead>) => (
        <div>{moment.parseZone(cell.value).format('MM/DD/YYYY')}</div>
      )
    },
    {
      Header: 'LEAD NAME',
      accessor: 'leadName'
    },
    {
      Header: 'LEAD EMAIL',
      accessor: 'leadEmail'
    },
    {
      Header: 'LEAD PHONE NUMBER',
      accessor: 'leadPhoneNumber'
    },
    {
      Header: 'STATUS',
      accessor: 'status'
    }
  ];

  return (
    <div className='container'>
      <Breadcrumbs />
      <div className='margin-top-24'>
        {referralLeads && (
          <CobuTable
            centerAlignedHeaderAndContent
            columns={referralLeadColumns}
            data={referralLeads as ICommunityLead[]}
            sort={{
              id: 'createdAt',
              descending: true
            }}
          />
        )}
      </div>
    </div>
  );
};
