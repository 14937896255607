import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import classes from './index.module.css';
import { BuildingEntrataIntegration } from '~/types/buildingEntrataIntegration';
import { FormField } from '~/components/FormField';
import MultiSelectCheckBox from '~/components/MultiSelectCheckBoxList/MultiSelectCheckBox';
import MultiSelectCheckboxList from '~/components/MultiSelectCheckBoxList/MultiSelectCheckBoxList';
import {
  UpdateYardiIntegrationPayload,
  YardiBuildingIntegration,
  YardiProperty,
  fetchYardiIntegrationForBuilding,
  getYardiProperties
} from '~/api/yardiIntegration';

interface YardiBuildingIntegrationFormProps {
  integration: UpdateYardiIntegrationPayload;
  onChange: (event: any) => any;
  index: number;
  properties: YardiProperty[];
}
export const YardiBuildingIntegrationForm = (
  props: YardiBuildingIntegrationFormProps
) => {
  const { integration, properties, index } = props;

  const { register, errors, formState, control } =
    useForm<UpdateYardiIntegrationPayload>({
      defaultValues: {
        // uuid: integration?.uuid,
        // building: buildingId,
        yardi_property_id: integration?.yardi_property_id ?? ''
      }
    });

  return (
    <div className={classes.gridContainer}>
      <div>
        <div className='flex-row align-center justify-between'>
          <div className='flex'>
            <label className='margin-top-16' htmlFor='entrata_password'>
              Yardi Property
            </label>
            <select
              name='yardi_property_id'
              value={integration.yardi_property_id}
              onChange={props.onChange}
              style={{ width: '400px', height: '34px' }}
            >
              <option value='' disabled>
                Select a active property
              </option>
              {properties.map((property) => (
                <option value={property.Code} key={property.Code}>
                  {property.MarketingName}
                </option>
              ))}
            </select>
          </div>

          <div className='flex margin-top-24'>
            <label htmlFor='leadSourceId'>Lead Source ID</label>
            <input
              id='leadSourceId'
              name='leadSourceId'
              value={integration.leadSourceId}
              onChange={(event) => {
                if (!event.target.value) {
                  return;
                }

                props.onChange(event);
              }}
              ref={register()}
              className={`${errors.leadSourceId && 'border-red'}`}
            />
          </div>
        </div>
        <div className='flex-row justify-between align-center'>
          <Controller
            name='default'
            defaultValue={false}
            render={({ onChange }) => (
              <MultiSelectCheckBox
                index={index}
                option={{
                  value: 'default',
                  text: 'Mark as default',
                  isSelected: integration.default
                }}
                setSelectedOption={(value, isSelected) => {
                  onChange(isSelected);
                  props.onChange({
                    target: { name: value, value: isSelected }
                  });
                }}
              />
            )}
            control={control}
          />
        </div>
      </div>
    </div>
  );
};

export default YardiBuildingIntegrationForm;
