import React, { useEffect, useState } from 'react';

import { ReactComponent as Location } from '~/utils/images/location.svg';
import { ReactComponent as AttendingIcon } from '~/utils/images/attending-icon.svg';
import { ReactComponent as CheckIcon } from '~/utils/images/check-icon.svg';
import { GatheringType } from '~/types/gathering';
import classes from './RSVPGatheringDetail.module.css';
import {
  shortDateTimeFormat,
  longDateTimeFormat
} from '~/helpers/dateTimeFormats';
import { UserMin } from '~/types/user';
import moment from 'moment';

interface IProps {
  interestedDate?: string;
  onInterestedDateChanged: (date: string) => void;
  gathering: GatheringType;
  creator: UserMin;
  onAttend: () => void;
  totalAttendees: number;
  isAttending: boolean;
  showCalendarModal: () => void;
  isAtCapacity: boolean;
  building: any;
  allGatherings: GatheringType[];
}

const RSVPGatheringDetail = (props: IProps) => {
  const {
    allGatherings,
    gathering,
    building,
    totalAttendees,
    isAttending,
    showCalendarModal,
    isAtCapacity,
    creator
  } = props;

  const { title, locationString, description, startTime, whatToBring, isRSVP } =
    gathering;

  const shortStartTime = shortDateTimeFormat(startTime, building.timezone);
  const longStartTime = longDateTimeFormat(
    gathering?.scheduledStartTime!,
    gathering.buildingTimezone
  );
  const longEndTime = gathering?.scheduledEndTime
    ? longDateTimeFormat(
        gathering?.scheduledEndTime!,
        gathering.buildingTimezone
      )
    : null;

  const isSameDate = moment(gathering?.scheduledStartTime).isSame(
    gathering?.scheduledEndTime,
    'date'
  );
  useEffect(() => {}, [isAtCapacity, totalAttendees]);
  const getButtonText = () => {
    if (!isRSVP && !isAttending) return `EXPRESS INTEREST`;
    else if (!isRSVP && isAttending) return `INTERESTED`;
    else if (!isAttending && isAtCapacity) {
      return 'AT CAPACITY';
    } else if (isAttending) {
      return 'ATTENDING';
    } else {
      return 'ATTEND';
    }
  };

  const onDateSelect = (e: any) => {
    props.onInterestedDateChanged(e.target.value);
  };

  return (
    <div className={classes.gathDetailContainer}>
      {gathering?.recurringTypeName != 'One Time' && (
        <div className='flex align-start margin-bottom-16 margin-left-24'>
          <b className='margin-right-16 margin-top-24'>Event Schedule </b>
          <div className='flex-row'>
            {/* {!moment(gathering.scheduledStartTime).isBefore(
              moment().tz(gathering.buildingTimezone)
            ) && (
              <button
                className={`margin-right-16 cursor-pointer btn btn-small btn-secondary`}
              >
                {moment(gathering.scheduledStartTime)
                  .tz(gathering?.buildingTimezone)
                  .format('dddd DD MMMM YYYY')}
              </button>
            )} */}
            {allGatherings?.slice(0, 5).map((event) => {
              if (event.scheduledStartTime) {
                return (
                  <button
                    className={`margin-right-16 cursor-pointer btn btn-small btn-secondary`}
                  >
                    {moment(event.scheduledStartTime)
                      .tz(gathering?.buildingTimezone)
                      .format('dddd DD MMMM YYYY')}
                  </button>
                );
              }
            })}
          </div>
          {allGatherings?.length - 6 > 0 && (
            <p> + {allGatherings.length - 6} more</p>
          )}
        </div>
      )}
      <div
        className={`${classes.titleAttendContainer} margin-top-24 margin-bottom-8`}
      >
        {title}
      </div>
      <div className={classes.titleAttendContainer}>
        <div
          className='flex align-start justify-start'
          style={{ height: '48px' }}
        >
          {gathering?.recurringTypeName == 'One Time' && (
            <div className={classes.gathPlaceDate}>
              <div className={classes.gathDateLocation}>{shortStartTime}</div>
            </div>
          )}
          {gathering?.recurringTypeName != 'One Time' &&
            allGatherings?.length != 0 && (
              <>
                <label>Event Date to RSVP</label>
                <select onChange={onDateSelect}>
                  <option disabled>Select Event Date</option>
                  {allGatherings?.map((event) => {
                    const displayValue = moment(event.scheduledStartTime)
                      .tz(gathering.buildingTimezone)
                      .format('dddd DD MMMM YYYY');

                    const date = moment(event.scheduledStartTime)
                      .tz(gathering.buildingTimezone)
                      .format('YYYY-MM-DD');

                    return (
                      <option
                        selected={date == props.interestedDate}
                        value={date}
                      >
                        {displayValue}
                      </option>
                    );
                  })}
                </select>
              </>
            )}
        </div>
        <button
          className={classes.attendButton}
          onClick={props.onAttend}
          disabled={isAtCapacity && !isAttending}
        >
          {getButtonText()}
          {isAttending && <CheckIcon width={20} height={20} />}
        </button>
      </div>
      <div className={classes.aboutContainer}>TIME</div>
      <div className={classes.descriptionBox}>
        {longStartTime} -{' '}
        {gathering?.scheduledEndTime
          ? isSameDate
            ? moment(gathering?.scheduledEndTime)
                .tz(gathering.buildingTimezone)
                .format('HH:mm A')
            : longEndTime
          : ''}
      </div>
      <div className={classes.aboutContainer}>ABOUT</div>
      <div className={classes.gathDetailContent}>
        <div className={classes.descriptionBox}>
          <div className={classes.descriptionContainer}>{description}</div>
          <div className={classes.buildingContainer}>BUILDING</div>
          <div>
            <div>{gathering.buildingName}</div>
          </div>
          {!!whatToBring && (
            <div className={classes.bringContainer}>
              <p>WHAT TO BRING</p>
              <p>{whatToBring}</p>
            </div>
          )}
        </div>
        <div className={classes.infoBox}>
          <div className={classes.yellowBox} />
          <div className={classes.infoContainer}>
            <div className={classes.infoDate}>{longStartTime}</div>
            <div className={classes.infoCreator}>
              <div className={classes.creatorContainer}>
                <div className={classes.creatorImageContainer}>
                  <img
                    className={classes.creatorProfileImage}
                    alt='creator-profile'
                    src={creator.profileImage}
                  />
                </div>
                <div className={classes.creatorName}>
                  {creator.firstName} {creator.lastName}
                  <div className={classes.gathCreator}>Gathering Creator</div>
                </div>
              </div>
            </div>
            <div className={classes.locationAttendingContainer}>
              <div>
                <div className={classes.iconTextContainer}>
                  <Location className={classes.icon} />
                  <div>{locationString}</div>
                </div>
                <div className={classes.iconTextContainer}>
                  <AttendingIcon className={classes.icon} />
                  <div>{totalAttendees} people attending</div>
                </div>
              </div>
              <button
                className={classes.addCalendarButton}
                onClick={showCalendarModal}
              >
                ADD TO CALENDAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RSVPGatheringDetail;
