export const truncateString = (str: string, maxLength = 20) => {
  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
};

export const toTitleCase = (str: string) => {
  if (!str) {
    return '';
  }

  return str.toLowerCase().replace(/\b\w/g, (str) => str.toUpperCase());
};

const toBold = (char: string) => {
  const boldUppercaseStart = 0x1d400; // Unicode starting point for bold uppercase letters
  const boldLowercaseStart = 0x1d41a; // Unicode starting point for bold lowercase letters
  const boldNumbersStart = 0x1d7ce; // Unicode starting point for bold numbers

  if ('A' <= char && char <= 'Z') {
    return String.fromCodePoint(
      boldUppercaseStart + char.charCodeAt(0) - 'A'.charCodeAt(0)
    );
  } else if ('a' <= char && char <= 'z') {
    return String.fromCodePoint(
      boldLowercaseStart + char.charCodeAt(0) - 'a'.charCodeAt(0)
    );
  } else if ('0' <= char && char <= '9') {
    return String.fromCodePoint(
      boldNumbersStart + char.charCodeAt(0) - '0'.charCodeAt(0)
    );
  } else {
    return char;
  }
};

export const convertToBold = (word: string) => {
  let result = '';
  for (let char of word) {
    result += toBold(char);
  }

  return result;
};
