import React from 'react';
import { IMultiSelectOptionList } from '~/helpers/interfaces';
import MultiSelectCheckBox from './MultiSelectCheckBox';

interface IProps {
  options: IMultiSelectOptionList[];
  setSelectedOption: (value: string, isSelected: boolean) => void;
}
const MultiSelectCheckboxList = (props: IProps) => {
  const { options, setSelectedOption } = props;

  return (
    <div>
      {options.map((option, index) => (
        <div key={index} style={option.containerStyle}>
          <MultiSelectCheckBox
            index={index}
            option={option}
            setSelectedOption={setSelectedOption}
          />
          {option.showSibling && option.sibling}
        </div>
      ))}
    </div>
  );
};
export default MultiSelectCheckboxList;
