import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import GatheringsTable from '~/components/GatheringsTable/GatheringsTable';
import { connect, ResolveThunks, useDispatch } from 'react-redux';
import moment from 'moment';

import { fetchGatheringsRequest } from '~/api/building';
import {
  fetchGatheringsSuccess,
  createGatheringSuccess
} from '~/reducers/building';
import { RootState } from '~/redux/store';

import './Gatherings.css';
import { ReactComponent as Search } from '~/utils/images/search.svg';
import { GatheringType } from '~/types/gathering';
import { Gathering } from '~/enums/Gathering';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spinner from '~/components/Spinner/Spinner';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';
import { saveUserSettings } from '~/reducers/auth';
import GatheringTemplates from '~/components/GatheringTemplates/GatheringTemplates';
import { Roles } from '~/enums/Roles';
import { useBuildingDashboardConfig } from '~/hooks/useBuildingDashboardConfig';
import classNames from 'classnames';

interface DispatchProps {
  fetchGatheringsSuccess: typeof fetchGatheringsSuccess;
  createGatheringSuccess: typeof createGatheringSuccess;
  saveUserSettings: typeof saveUserSettings;
}

type Props = ResolveThunks<DispatchProps> & ReturnType<typeof mapStateToProps>;

let isMounted = false;

const Gatherings = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [showSearchField, setShowSearchField] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState<GatheringType[]>([]);
  const [showTemplates, setShowTemplates] = useState(false);
  const { buildingId } = useParams<{ buildingId: string }>();
  const selectedBuilding = getBuildingFromStore(buildingId);

  const { fetchGatheringsSuccess, saveUserSettings, userSettings, gatherings } =
    props;

  const [selectedGatherings, setSelectedGatherings] = useState<Gathering>(
    userSettings.gatheringActiveTabIndex || 0
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const onDuplicateClick = (gathering: GatheringType) => {
    history.push(
      `/building/${gathering?.buildingId}/gathering/${gathering?.uuid}/duplicate`
    );
  };

  const fetchData = () => {
    fetchGatheringsRequest(selectedBuilding.uuid).then((res) => {
      if (isMounted) {
        fetchGatheringsSuccess(res.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    isMounted = true;
    selectedBuilding && fetchData();
    return () => {
      isMounted = false;
    };
  }, [fetchGatheringsSuccess, selectedBuilding]);

  const { data: buildingDashboardConfigData } =
    useBuildingDashboardConfig(buildingId);

  useEffect(() => {
    switch (selectedGatherings) {
      case Gathering.UPCOMING:
        setTableData(
          gatherings.filter((g: GatheringType) => {
            return moment().isBefore(
              moment(g.scheduledStartTime).tz(g.buildingTimezone).add(1, 'hour')
            );
          })
        );
        dispatch(
          saveUserSettings({
            ...userSettings,
            gatheringActiveTabIndex: Gathering.UPCOMING
          })
        );
        break;
      case Gathering.PAST:
        setTableData(
          gatherings.filter((g: GatheringType) =>
            moment().isAfter(moment(g.scheduledStartTime).add(1, 'hour'))
          )
        );
        dispatch(
          saveUserSettings({
            ...userSettings,
            gatheringActiveTabIndex: Gathering.PAST
          })
        );
        break;
      case Gathering.SCHEDULED:
        setTableData([]); // TODO
        break;
      default:
        setTableData(gatherings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGatherings, gatherings, dispatch]);

  const allowViewingGatheringTemplates =
    props.userRole === Roles.Vcm ||
    props.userRole === Roles.Superadmin ||
    (props.userRole === Roles.Pm &&
      buildingDashboardConfigData?.data.config.showGatheringTemplates);

  const renderTable = () => {
    if (showTemplates) {
      return (
        <GatheringTemplates
          searchValue={searchValue}
          userRole={props.userRole}
        />
      );
    }
    return (
      <GatheringsTable
        data={tableData.filter((g: GatheringType) => {
          return (
            g.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            g?.groupName?.toLowerCase().includes(searchValue.toLowerCase())
          );
        })}
        gatheringType={selectedGatherings}
        duplicateGatheringHandler={onDuplicateClick}
        getGatherings={fetchData}
      />
    );
  };

  return (
    <div className='gathering-container'>
      {loading && <Spinner />}
      {!loading && (
        <div>
          <Breadcrumbs />
          <p className='h1-title'>
            Gatherings in <strong>{selectedBuilding.name}</strong>
          </p>
          <div className='flex menu-bar'>
            <div className='flex-row justify-start' style={{ width: '100%' }}>
              <div className='tab-container pointer'>
                <div
                  className={
                    !showTemplates && selectedGatherings === Gathering.UPCOMING
                      ? 'gatherings-btn-selected upcoming'
                      : 'gatherings-btn upcoming'
                  }
                  onClick={() => {
                    setShowTemplates(false);
                    setSelectedGatherings(Gathering.UPCOMING);
                  }}
                >
                  <p className='bold'>UPCOMING GATHERINGS</p>
                </div>
                <div
                  className={classNames(
                    'gatherings-btn',
                    allowViewingGatheringTemplates ? 'past' : 'template',
                    {
                      'gatherings-btn-selected':
                        !showTemplates && selectedGatherings === Gathering.PAST
                    }
                  )}
                  onClick={() => {
                    setShowTemplates(false);
                    setSelectedGatherings(Gathering.PAST);
                  }}
                >
                  <p className='bold'>PAST GATHERINGS</p>
                </div>
                {allowViewingGatheringTemplates && (
                  <div
                    className={
                      showTemplates
                        ? 'gatherings-btn-selected template'
                        : 'gatherings-btn template'
                    }
                    onClick={() => setShowTemplates(true)}
                  >
                    <p className='bold'>GATHERING TEMPLATES</p>
                  </div>
                )}
              </div>
            </div>
            <div
              className='flex-row justify-between margin-top-24 align-center'
              style={{ width: '100%' }}
            >
              {showSearchField ? (
                <div
                  className='search-expanded pointer'
                  onClick={() => {
                    setSearchValue('');
                    setShowSearchField(false);
                  }}
                >
                  <Search className='search-icon' />
                  <input
                    className='search-input'
                    placeholder='Search fields'
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              ) : (
                <div
                  className='search pointer'
                  onClick={() => setShowSearchField(true)}
                >
                  <Search />
                </div>
              )}
              <div className='flex-row'>
                <div className='gatherings-new-btn'>
                  <button
                    className='btn-primary btn-standard'
                    onClick={() =>
                      history.push(
                        `/building/${selectedBuilding.uuid}/gathering/create`
                      )
                    }
                  >
                    Create new Gathering
                  </button>
                </div>
                {(props.userRole === Roles.Vcm ||
                  props.userRole === Roles.Superadmin) && (
                  <div className='gatherings-new-btn'>
                    <button
                      className='btn-primary btn-standard'
                      onClick={() =>
                        history.push(
                          `/building/${selectedBuilding.uuid}/gathering-template/create`
                        )
                      }
                    >
                      Create new template
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {renderTable()}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    buildings: state.building.buildings,
    gatherings: state.building.gatherings,
    userSettings: state.auth.userSettings,
    userRole: state.auth.userRole
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    fetchGatheringsSuccess,
    createGatheringSuccess,
    saveUserSettings
  };
}

export default connect(mapStateToProps, mapDispatchToProps())(Gatherings);
