import momentTimezone from "moment-timezone";
import React from "react";
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs";
import CobuTableAdvanced from "~/components/CobuTableAdvanced/CobuTableAdvanced";
import Spinner from "~/components/Spinner/Spinner";
import CheckBox from "~/components/CheckBox/CheckBox";
import Spacer from "~/components/Spacer/Spacer";
import { UpcomingEvent } from "~/types/gathering";
import useUpcomingEvents from "~/hooks/events/useUpcomingEvents";
import CobuConstants from "~/helpers/cobuConstants";
import Switch from "react-ios-switch";
import styles from './EventManagement.module.css';

const EventManagement = () => {
    const { upcomingEvents, loadingConfig, toggleDataForEvent } = useUpcomingEvents();

    //UI Logics

    const renderStartTime = (cell: UpcomingEvent) => {
        const startTime = momentTimezone
            .tz(cell.startTime, cell.building.timezone)
            .format('MM/DD/YYYY - hh:mm A')
        return <div>{startTime}</div>;
    };

    const renderRSVPCell = (cell: UpcomingEvent) => {
        return cell.isRSVP ? <CheckBox index={0} option={{ text: "", value: "" }} selectedOption={""} setSelectedOption={() => { }} /> : null;
    };

    const renderAutoRemainderSwitch = (cell: UpcomingEvent) => {
        return (
            <div>
                <Switch checked={cell.reqAutoReminder} onChange={() => toggleDataForEvent(cell)} />
            </div>
        );
    };

    const upcomingEventTableConfig = [
        { Header: 'TITLE', accessor: 'title' },
        { Header: 'BUILDING', accessor: 'building.name' },
        { Header: 'GROUP', accessor: 'group.name' },
        { Header: 'CREATOR', accessor: (cell: UpcomingEvent) => `${cell.user.firstName} ${cell.user.lastName}` },
        { Header: 'CREATOR EMAIL', accessor: 'user.email' },
        { Header: 'START TIME', accessor: renderStartTime },
        { Header: 'RSVP', accessor: renderRSVPCell },
        { Header: 'REMAINING RSVPs', accessor: (cell: UpcomingEvent) =>  cell.maxEventAttendees ? `${cell.maxEventAttendees - cell.rsvpcount}` : '∞' },
        { Header: 'AUTO REMINDER', accessor: renderAutoRemainderSwitch }
    ];

    const getCobuTableInstance = (data: UpcomingEvent[], placeholder: string) => (
        <CobuTableAdvanced
            columns={upcomingEventTableConfig}
            data={data}
            noData={{ label: placeholder, action: undefined, image: undefined }}
            actionsStyle={{ top: '130px' }}
        />
    );

    if (loadingConfig.flag) {
        return (
            <div className={styles.loadingContainer}>
                <Spinner />
                <h3 style={{ textAlign: 'center' }}>{loadingConfig.comment}</h3>
            </div>
        );
    }

    return (
        <div className='content-container'>
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.eventManagement.title}</h1>
            <h4>{`This table showcases all events scheduled to occur greater than or equal to three days from the current date. `}</h4>
            <Spacer height={20} />
            {getCobuTableInstance(upcomingEvents, CobuConstants.pinnedPostManagement.noPinnedPosts)}
        </div>
    );
};

export default EventManagement;
