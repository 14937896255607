import moment from "moment";
import React, { useState } from "react";
import { CellProps } from "react-table";
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs";
import CobuTableAdvanced from "~/components/CobuTableAdvanced/CobuTableAdvanced";
import Spacer from "~/components/Spacer/Spacer";
import Spinner from "~/components/Spinner/Spinner";
import CobuConstants from "~/helpers/cobuConstants";
import styles from './ActiveQueues.module.css';
import useQueues from "~/hooks/queues/useQueues";
import { Queue } from "~/types/queues";
import { useHistory } from "react-router-dom";

const ActiveQueues = () => {
    const { activeQueues, loadingConfig } = useQueues();
    const history = useHistory();
    /* UI Components */

    const activeQueuesTableConfig = [
        {
            Header: 'PROCESS',
            accessor: 'title',
            Cell: (cell: CellProps<Queue>) => (
                <div style={{ whiteSpace: 'normal' }} className='default-text'>
                    {cell.row.original.title}
                </div>
            ),
        },
        {
            Header: 'RUN DATE',
            accessor: 'createdAt',
            Cell: (cell: CellProps<Queue>) => (
                <div>
                    {moment(cell.row.original.createdAt).local().format('MM/DD/YYYY')}
                </div>
            ),
        },
        {
            Header: 'STATUS',
            accessor: 'status',
            Cell: (cell: CellProps<Queue>) => {
                const status = cell.row.original.status;
                const statusClass = getStatusClass(status); // Function to determine class

                return (
                    <div className={`${styles.status} ${statusClass}`}>
                        {status.toUpperCase()}
                    </div>
                );
            }
        },

        {
            Header: 'ACTION',
            id: 'showDetails',
            Cell: (cell: CellProps<Queue>) => (
                <button
                    className='btn-primary btn-standard'
                    onClick={() => { 
                        history.push(`/building/queue/${cell.row.original.uuid}`)
                    }}>
                    {CobuConstants.activeQueues.showDetails}
                </button>
            )
        }
    ];


    const getStatusClass = (status: string) => {
        switch (status) {
            case 'pending':
                return styles.pending;
            case 'processing':
                return styles.processing;
            case 'completed':
                return styles.completed;
            case 'failed':
                return styles.failed;
            default:
                return '';
        }
    };
    

    const getCobuTableInstance = (data: Queue[], placeholder: string) => {
        return (
            <CobuTableAdvanced
                columns={activeQueuesTableConfig}
                data={data}
                noData={{
                    label: placeholder,
                    action: undefined,
                    image: undefined
                }}
                sort={{ id: 'createdAt', descending: true }}
            />
        );
    };

    if (loadingConfig.flag) return (
        <div className={styles.loadingContainer}>
            <Spinner />
            <h3 style={{ textAlign: 'center' }}>{loadingConfig.comment}</h3>
        </div>
    );


    return (
        <div className='content-container'>
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.activeQueues.title}</h1>
            <Spacer height={20} />
            {getCobuTableInstance(activeQueues, CobuConstants.activeQueues.noActiveQueues)}
        </div>
    );
}

export default ActiveQueues;