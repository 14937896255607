import React, { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Control, Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  IPayoutTypes,
  IReferralOffer,
  IReferralStatus,
  getLandingPageOptions,
  getPayoutOptions,
  getReferralOffer,
  getReferralStatusOptions,
  postReferralOffer
} from '~/api/referral';
import { ICreateFlyerFormData } from '~/containers/Referral/CreateEditReferralForm/interfaces';
import { ReferralIntroMarkups } from '~/containers/Referral/constants';
import { numberRegex } from '~/helpers/helpers';
import { ISelectOption } from '~/helpers/interfaces';
import { FormInputConfig } from '~/types/residentManagement';
import useGetBuildingDetails from '../useGetBuildingDetails';
import { Building } from '~/types/building';
import moment from 'moment';
import { updateBuilding } from '~/api/building';

export default (buildingId: string) => {
  const [loader, setLoader] = useState(false);
  const [referralOffer, setReferralOffer] = useState<IReferralOffer | null>(
    null
  );
  const [payoutOptions, setPayoutOptions] = useState<ISelectOption[] | null>(
    []
  );

  const [landingPageOptions, setLandingPageOptions] = useState<
    ISelectOption[] | null
  >([]);
  const [referralStatusOptions, setReferralStatusOptions] = useState<
    ISelectOption[] | null
  >([]);

  const buildingDetails = useGetBuildingDetails(buildingId, true);

  const propertyLogoUrl = (buildingDetails as unknown as Partial<Building>)
    ?.propertyLogoUrl as string;

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: { dirtyFields, isDirty }
  } = useForm<ICreateFlyerFormData>({
    defaultValues: {
      bannerTitle: 'Refer a resident',
      logoBackgroundColor: 'transparent'
    }
  });

  useEffect(() => {
    if (referralOffer && buildingDetails) {
      const {
        cobuPayout,
        residentPayout,
        description,
        title,
        bannerTitle,
        endDate,
        startDate,
        images,
        payoutType,
        referralLandingPageOption,
        referralStatus,
        rulesAndEligibility,
        landingpageIntroParagraph
      } = referralOffer;

      const logoBackgroundColor = (
        buildingDetails as unknown as Partial<Building>
      )?.details?.logoBackgroundColor;

      setTimeout(() => {
        reset({
          cobuPayout,
          residentPayout,
          description,
          title,
          bannerTitle: bannerTitle || 'Refer a resident',
          endDate: moment.parseZone(endDate).format('YYYY-MM-DD'),
          startDate: moment.parseZone(startDate).format('YYYY-MM-DD'),
          images: images?.[0],
          payoutType,
          referralLandingPageOption,
          referralStatus,
          rulesAndEligibility,
          landingpageIntroParagraph,
          logoBackgroundColor
        });
      }, 0);
    }
  }, [referralOffer, buildingDetails]);

  const fetchReferralOffer = async () => {
    try {
      setLoader(true);

      const res = await getReferralOffer(buildingId);

      if (!!res.data) {
        setReferralOffer(res.data);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const postReferralData = async (formData: ICreateFlyerFormData) => {
    const { logoBackgroundColor, ...referralOfferData } = formData;

    try {
      setLoader(true);

      const referralFormData = new FormData();

      Object.entries(referralOfferData).forEach(([key, value]) => {
        if (typeof value === 'string') {
          referralFormData.append(key, value);
        }
      });

      referralFormData.append('buildingId', buildingId);
      referralOfferData.images &&
        referralFormData.append('images', referralOfferData.images);

      await postReferralOffer(referralFormData);

      logoBackgroundColor &&
        (await updateBuilding(
          { details: JSON.stringify({ logoBackgroundColor }) },
          buildingId
        ));

      toast('Referral offer pushed successfully');

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const fetchPayoutOptions = async () => {
    try {
      const res = await getPayoutOptions();

      const options: ISelectOption[] = res.data.map(
        (payoutType: IPayoutTypes) => {
          return { value: payoutType.uuid, label: payoutType.name };
        }
      );

      setPayoutOptions(options);
    } catch (e) {}
  };

  const fetchLandingPageOptions = async () => {
    try {
      const res = await getLandingPageOptions();

      const options: ISelectOption[] = res.data.map(
        (payoutType: IPayoutTypes) => {
          return { value: payoutType.uuid, label: payoutType.name };
        }
      );

      setLandingPageOptions(options);
    } catch (e) {}
  };

  const fetchReferralStatusOptions = async () => {
    try {
      const res = await getReferralStatusOptions();

      const options: ISelectOption[] = res.data.map(
        (payoutType: IReferralStatus) => {
          return { value: payoutType.uuid, label: payoutType.name };
        }
      );

      setReferralStatusOptions(options);
    } catch (e) {}
  };

  useEffect(() => {
    Promise.all([
      fetchPayoutOptions(),
      fetchReferralStatusOptions(),
      fetchLandingPageOptions()
    ]).then(() => {
      fetchReferralOffer();
    });
  }, []);

  const backgroundColor = watch('logoBackgroundColor');

  const createReferralFormConfig: FormInputConfig[] = [
    {
      label: 'Landing page option',
      id: 'referralLandingPageOption',
      inputType: 'select',
      selectOptions: landingPageOptions as ISelectOption[],
      isRequired: true,
      placeholder: 'Select landing page option'
    },
    {
      label: 'Referral Banner Title',
      id: 'bannerTitle',
      isRequired: true,
      placeholder: 'Enter Referral Banner Title'
    },
    {
      label: 'Referral Title',
      id: 'title',
      isRequired: true,
      placeholder: 'Enter Referral Title'
    },
    {
      label: 'Referral Description',
      id: 'description',
      isRequired: true,
      inputType: 'textarea',
      placeholder: 'Enter Referral Description'
    },
    {
      label: 'Resident Payout',
      id: 'residentPayout',
      pattern: numberRegex(),
      patternErrorMsg: 'Oops! Please enter a valid number',
      isRequired: true,
      placeholder: 'Enter Resident Payout'
    },
    {
      label: 'Cobu Payout',
      id: 'cobuPayout',
      pattern: numberRegex(),
      patternErrorMsg: 'Oops! Please enter a valid number',
      isRequired: true,
      placeholder: 'Enter Cobu Payout'
    },
    {
      label: 'Payout Type',
      id: 'payoutType',
      inputType: 'select',
      selectOptions: payoutOptions as ISelectOption[],
      isRequired: true,
      placeholder: 'Enter Cobu Payout'
    },
    ...((referralOffer
      ? [
          {
            label: 'Referral Status',
            id: 'referralStatus',
            inputType: 'select',
            selectOptions: referralStatusOptions as ISelectOption[],
            isRequired: true,
            placeholder: 'Enter Cobu Referral Status'
          }
        ]
      : []) as FormInputConfig[]),
    {
      label: 'Rules and Eligibility',
      id: 'rulesAndEligibility',
      inputType: 'textarea',
      isRequired: true,
      placeholder: 'Enter Rules and Eligibility'
    },
    {
      label: 'Start Date',
      id: 'startDate',
      inputType: 'date',
      isRequired: true
    },
    {
      label: 'End Date',
      id: 'endDate',
      inputType: 'date',
      isRequired: true
    },
    {
      label: 'Referral Image',
      id: 'images',
      inputType: 'imageDropzone',
      isRequired: true,
      placeholder: 'Select Referral Image',
      styles: {
        lableStyles: { marginBottom: '10px' }
      }
    },
    {
      label: `Landing Page Intro(For sharable link) :: ${ReferralIntroMarkups.referredBy} ${ReferralIntroMarkups.buildingName}`,
      id: 'landingpageIntroParagraph',
      inputType: 'textarea',
      isRequired: false,
      placeholder: `Please enter page intro. Allowed markups: ${ReferralIntroMarkups.referredBy} ${ReferralIntroMarkups.buildingName}`
    },
    {
      label: 'Logo background color',
      id: 'logoBackgroundColor',
      inputType: 'custom',
      styles: {
        lableStyles: { marginBottom: '10px' }
      },
      sibling: (
        <input
          type='text'
          name='logoBackgroundColor'
          defaultValue={backgroundColor as unknown as string}
          ref={register()}
        />
      ),
      customComponent: (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0 20px' }}>
          {propertyLogoUrl ? (
            <img
              width={100}
              height={100}
              src={propertyLogoUrl}
              style={{
                backgroundColor: backgroundColor as unknown as string,
                padding: 50,
                borderWidth: 1,
                borderStyle: 'dotted',
                borderColor: 'black'
              }}
            />
          ) : (
            <button
              onClick={() => {
                history.push(
                  `/building/${buildingId}/edit?showEditSetting=true`
                );
              }}
              className='btn btn-standard btn-primary'
            >
              Add Building Logo
            </button>
          )}
          <Controller
            name={'logoBackgroundColor'}
            defaultValue={backgroundColor as unknown as string}
            control={control}
            render={({ onChange }) => {
              return (
                <HexColorPicker
                  color={backgroundColor as string}
                  onChange={(color) => {
                    onChange(color);
                  }}
                />
              );
            }}
          />
        </div>
      ),
      isRequired: false,
      placeholder: 'Select Referral Image background'
    }
  ];

  return {
    loader,
    referralOffer,
    postReferralData,
    createReferralFormConfig,
    register,
    handleSubmit,
    errors,
    control,
    reset,
    isDirty,
    dirtyFields
  };
};
