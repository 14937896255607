import React from 'react';

import { ReactComponent as CheckboxUnselected } from '~/utils/images/checkbox-unchecked.svg';
import { ReactComponent as CheckboxSelected } from '~/utils/images/checkbox-checked.svg';
import { IMultiSelectOption } from '~/helpers/interfaces';
import './MultiSelectCheckBox.css';

interface IProps {
  index: number;
  option: IMultiSelectOption;
  setSelectedOption: (value: string, isSelected: boolean) => void;
}
const MultiSelectCheckBox = (props: IProps) => {
  const { option, setSelectedOption, index } = props;

  return (
    <div
      key={index}
      className='checkbox-row'
      onClick={() => setSelectedOption(option.value, !option.isSelected)}
    >
      {option.isSelected ? <CheckboxSelected /> : <CheckboxUnselected />}
      <p className='margin-left-16'>{option.text}</p>
    </div>
  );
};
export default MultiSelectCheckBox;
