import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import classes from './index.module.css';
import { BuildingEntrataIntegration } from '~/types/buildingEntrataIntegration';
import { FormField } from '~/components/FormField';
import MultiSelectCheckBox from '~/components/MultiSelectCheckBoxList/MultiSelectCheckBox';
import MultiSelectCheckboxList from '~/components/MultiSelectCheckBoxList/MultiSelectCheckBoxList';

interface EntrataBuildingIntegrationFormProps {
  entrataIntegration: BuildingEntrataIntegration;
  onChange: (event: any) => any;
  index: number;
}
export const EntrataBuildingIntegrationForm = (
  props: EntrataBuildingIntegrationFormProps
) => {
  const { entrataIntegration, index } = props;

  const { register, errors, formState, control } =
    useForm<BuildingEntrataIntegration>({
      defaultValues: {
        // uuid: entrataIntegration?.uuid,
        // building: buildingId,
        entrata_domain: entrataIntegration?.entrata_domain ?? '',
        entrata_username: entrataIntegration?.entrata_username ?? '',
        entrata_password: entrataIntegration?.entrata_password ?? '',
        entrata_property_id: entrataIntegration?.entrata_property_id ?? '',
        leadSourceId: entrataIntegration?.leadSourceId ?? '',
        default: !!entrataIntegration?.default
      }
    });

  return (
    <div className={classes.gridContainer}>
      <div className={classes.item}>
        <label className='margin-top-16' htmlFor='entrata_domain'>
          Domain *
        </label>
        <input
          type='text'
          id='entrata_domain'
          name='entrata_domain'
          value={entrataIntegration.entrata_domain}
          onChange={props.onChange}
          ref={register({
            required: 'This field is required'
          })}
          className={`${errors.entrata_domain && 'border-red'} height-50`}
        />
      </div>
      <div className={classes.item}>
        <label className='margin-top-16' htmlFor='entrata_property_id'>
          Property ID *
        </label>
        <input
          type='text'
          id='entrata_property_id'
          name='entrata_property_id'
          value={entrataIntegration.entrata_property_id}
          onChange={props.onChange}
          ref={register({
            required: 'This field is required'
          })}
          className={`${errors.entrata_property_id && 'border-red'} height-50`}
        />
      </div>
      <div className={classes.item}>
        <label className='margin-top-16' htmlFor='entrata_username'>
          Username *
        </label>
        <input
          type='text'
          id='entrata_username'
          name='entrata_username'
          value={entrataIntegration.entrata_username}
          onChange={props.onChange}
          ref={register({
            required: 'This field is required'
          })}
          className={`${errors.entrata_username && 'border-red'} height-50`}
        />
      </div>
      <div className={classes.item}>
        <label className='margin-top-16' htmlFor='entrata_password'>
          Password *
        </label>
        <input
          type='pasword'
          id='entrata_password'
          name='entrata_password'
          value={entrataIntegration.entrata_password}
          onChange={props.onChange}
          ref={register({
            required: 'This field is required'
          })}
          className={`${errors.entrata_password && 'border-red'} height-50`}
        />
      </div>
      <div className={classes.item}>
        <label className='margin-top-16' htmlFor='entrata_password'>
          Lead Source ID
        </label>
        <input
          id='leadSourceId'
          name='leadSourceId'
          value={entrataIntegration.leadSourceId}
          onChange={(event) => {
            if (!event.target.value) {
              return;
            }

            props.onChange(event);
          }}
          ref={register()}
          className={`${errors.entrata_password && 'border-red'} height-50`}
        />
      </div>
      <Controller
        name='default'
        defaultValue={false}
        render={({ onChange }) => (
          <MultiSelectCheckBox
            index={index}
            option={{
              value: 'default',
              text: 'Mark as default',
              isSelected: entrataIntegration.default
            }}
            setSelectedOption={(value, isSelected) => {
              onChange(isSelected);
              props.onChange({
                target: { name: value, value: isSelected }
              });
            }}
          />
        )}
        control={control}
      />
    </div>
  );
};

export default EntrataBuildingIntegrationForm;
