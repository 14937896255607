import React, { ChangeEvent, useEffect, useState } from 'react';
import classes from './UploadRentRoll.module.css';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { Building } from '~/types/building';
import { fetchBuildingDetails } from '~/api/building';
import { useParams } from 'react-router-dom';
import UploadRentRollCsv from './UploadRentRollCsv';
import ColumnMatch from './ColumnMatch';
import { uniqueId } from 'lodash';
import { TenantInfo } from './TenantRentRollTable';
import { bulkAddTenants, bulkMoveOutTenants } from '~/api/resident';
import { toast } from 'react-toastify';
import { Colors } from '~/enums/Colors';

const UploadRentRoll = () => {
  const [parsedCsv, setparsedCsv] = useState<{
    rows: any[];
    maxColumns: number;
  }>();
  const [building, setbuilding] = useState<Building>();
  const [selectedStartingRow, setselectedStartingRow] = useState<any>();
  const { buildingId } = useParams<{ buildingId: string }>();
  const [currentStep, setCurrentStep] = useState<
    | 'UPLOAD_RENT_ROLL'
    | 'RENTROLL_COLUMN_SELECTION_TABLE'
    | 'UPLOAD_MOVEOUT_LIST'
    | 'MOVEOUT_COLUMN_SELECTION_TABLE'
    | ''
  >('');
  const [fieldMapping, setFieldMapping] = useState<any>({});
  const [uploadModalVisible, setuploadModalVisible] = useState<boolean>(false);

  const reset = () => setCurrentStep('');

  const openUploadRentrollCsvModal = () => {
    setuploadModalVisible(true);
    setCurrentStep('UPLOAD_RENT_ROLL');
  };
  const openUploadMoveoutCsvModal = () => {
    setuploadModalVisible(true);
    setCurrentStep('UPLOAD_MOVEOUT_LIST');
  };
  const closeUploadModal = () => {
    setCurrentStep('');
    setuploadModalVisible(false);
  };

  const { loader } = useFullScreenLoader();

  const getBuilding = () => {
    loader.show();
    fetchBuildingDetails(buildingId)
      .then((res) => {
        setbuilding(res.data);
      })
      .finally(() => loader.hide());
  };

  const onUploadSuccess = ({
    rows,
    maxColumns
  }: {
    rows: any[];
    maxColumns: number;
  }) => {
    const rowsWithId = rows?.map((row) => {
      row.id = uniqueId();
      return row;
    });

    setparsedCsv({
      rows: rowsWithId,
      maxColumns
    });

    setCurrentStep('RENTROLL_COLUMN_SELECTION_TABLE');
  };

  const onMoveOutListParsed = ({
    rows,
    maxColumns
  }: {
    rows: any[];
    maxColumns: number;
  }) => {
    const rowsWithId = rows?.map((row) => {
      row.id = uniqueId();
      return row;
    });

    setparsedCsv({
      rows: rowsWithId,
      maxColumns
    });

    setCurrentStep('MOVEOUT_COLUMN_SELECTION_TABLE');
  };

  const onStartingRowSelected = (row: any) => {
    setselectedStartingRow(row);
    const element = document.getElementById(`column-match-radio-${row.id}`);

    if (element?.attributes) {
      // @ts-ignore
      element.checked = true;

      // element.attributes['checked'] = true;
    }
  };

  const getTargetedRows = (rows: any[], selectedRowId: string) => {
    const index = rows.findIndex((row) => row.id == selectedRowId);

    const targetRows = rows.slice(index);

    return targetRows;
  };

  const withFirstAndLastName = (tenant: TenantInfo) => {
    if (tenant.fullName && !tenant.firstName && !tenant.lastName) {
      const [firstName, ...lastName] = tenant.fullName.split(' ');
      tenant.firstName = firstName;
      tenant.lastName = lastName.join(' ');
    }
    return tenant;
  };

  const checkIfEmailColumnIsSelected = () => {
    const selectedFields = Object.values(fieldMapping);

    if (!selectedFields.includes('email')) {
      toast.error('Email column not selected from column match table');
      throw Error('email not selected from column match table');
    }
  };

  const prepareTenantInfo = ({
    rows,
    buildingId
  }: {
    rows: any[];
    buildingId: string;
  }) => {
    const findalData: TenantInfo[] = [];

    const fields: string[] = Object.keys(fieldMapping);

    checkIfEmailColumnIsSelected();

    rows.forEach((row) => {
      let newRow: TenantInfo = {
        building: buildingId
      } as TenantInfo;

      const details = { ...row, ...newRow, id: undefined };

      fields.forEach((field: string) => {
        const key = fieldMapping[field] as keyof TenantInfo;
        newRow[key] = row[field];
      });

      newRow = withFirstAndLastName(newRow);
      newRow.details = details;

      if (newRow.email) {
        findalData.push(newRow);
      }
    });

    return findalData;
  };

  const displayTenantsImportSuccessAlert = (result: any) => {
    alert(
      `Tenants successfully imported.
      \n Move ins - ${result?.moveIns?.length ?? 0}
      \n Transfers - ${result?.transfers?.length ?? 0}
      \n Move outs - ${result?.moveOuts?.length}
      \n Leads created - ${result?.leadsCreated?.length ?? 0}`
    );
  };

  const displayMoveoutSuccessAlert = (result: any) => {
    alert(
      `Tenants successfully moved out.
      \n Move outs - ${result?.success?.length ?? 0}`
    );
  };

  const uploadRentRoll = () => {
    try {
      if (!selectedStartingRow?.id) {
        return toast.error('Starting row not selected');
      }

      const targetRows = getTargetedRows(
        parsedCsv?.rows ?? [],
        selectedStartingRow.id
      );

      const tenants = prepareTenantInfo({
        rows: targetRows,
        buildingId: buildingId
      });

      loader.show();

      bulkAddTenants({ building: buildingId, tenants })
        .then((res) => {
          const { result } = res.data;
          toast.success(res.data?.message);
          displayTenantsImportSuccessAlert(result);
          setCurrentStep('');
        })
        .finally(() => {
          loader.hide();
        });
    } catch (error) {
      console.error(error);
    }
  };

  const uploadMoveoutList = () => {
    try {
      if (!selectedStartingRow?.id) {
        return toast.error('Starting row not selected');
      }

      const targetRows = getTargetedRows(
        parsedCsv?.rows ?? [],
        selectedStartingRow.id
      );

      const tenants = prepareTenantInfo({
        rows: targetRows,
        buildingId: buildingId
      });

      loader.show();

      bulkMoveOutTenants({
        building: buildingId,
        moveOuts: tenants
      })
        .then((res) => {
          const { result } = res.data;
          toast.success(res.data?.message);
          displayMoveoutSuccessAlert(result);
          setCurrentStep('');
        })
        .finally(() => {
          loader.hide();
        });
    } catch (error) {
      console.error(error);
    }
  };

  const columnNameSelected = (e: ChangeEvent<any>) => {
    setFieldMapping({
      ...fieldMapping,
      [e.target.id]: e.target.value
    });
  };

  useEffect(() => {
    getBuilding();
  }, []);

  return (
    <div className={classes.pageWrapper}>
      {['', 'UPLOAD_RENT_ROLL', 'UPLOAD_MOVEOUT_LIST'].includes(
        currentStep
      ) && (
        <div
          className={`flex-row align-start justify-between ${classes.headerWrapper}`}
        >
          <div>
            <div
              className='margin-bottom-16'
              style={{ fontSize: '32px', color: Colors.cobuDarkBlue }}
            >
              Tenant Management in <b>{building?.name}</b>
            </div>
            <div
              className='margin-top-16 margin-bottom-8'
              style={{ fontSize: '32px', color: Colors.cobuDarkBlue }}
            >
              Rent Roll
            </div>
            <p>
              From here, you can transfer, move in and move out residents in
              their respective properties. If there are no residents listed,
              either check their PMS Integration settings, or upload a new rent
              roll file.
            </p>
          </div>
          <div className='flex-row justify-end'>
            <div className='margin-right-16'>
              <button
                className='btn-standard btn-primary btn-small'
                onClick={openUploadRentrollCsvModal}
              >
                Upload Rent Roll
              </button>
              <p>Transfers, Move In, And Move Out</p>
            </div>
            <div>
              <button
                className='btn-standard btn-danger btn-small'
                onClick={openUploadMoveoutCsvModal}
              >
                Upload Move Out List
              </button>
              <p>ONLY Move Out</p>
            </div>
          </div>
        </div>
      )}
      <UploadRentRollCsv
        isVisible={uploadModalVisible && currentStep == 'UPLOAD_RENT_ROLL'}
        onClose={closeUploadModal}
        onUpload={onUploadSuccess}
        title='Upload Rent Roll'
      />
      <UploadRentRollCsv
        isVisible={uploadModalVisible && currentStep == 'UPLOAD_MOVEOUT_LIST'}
        onClose={closeUploadModal}
        onUpload={onMoveOutListParsed}
        title='Upload Moveouts'
      />
      {currentStep == 'RENTROLL_COLUMN_SELECTION_TABLE' && parsedCsv && (
        <ColumnMatch
          rows={parsedCsv.rows}
          fieldMapping={fieldMapping}
          maxColumns={parsedCsv.maxColumns}
          onRowSelection={onStartingRowSelected}
          selectedRow={selectedStartingRow}
          buildingId={buildingId}
          onUpload={uploadRentRoll}
          onColumnNameSelected={columnNameSelected}
          onCancel={reset}
          uploadActionTitle={'Upload Residents'}
        />
      )}
      {currentStep == 'MOVEOUT_COLUMN_SELECTION_TABLE' && parsedCsv && (
        <ColumnMatch
          rows={parsedCsv.rows}
          fieldMapping={fieldMapping}
          maxColumns={parsedCsv.maxColumns}
          onRowSelection={onStartingRowSelected}
          selectedRow={selectedStartingRow}
          buildingId={buildingId}
          onUpload={uploadMoveoutList}
          onColumnNameSelected={columnNameSelected}
          onCancel={reset}
          uploadActionTitle={'Moveout Residents'}
        />
      )}
    </div>
  );
};

export default withFullScreenLoader(UploadRentRoll);
