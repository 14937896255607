import React, { useEffect, useState } from 'react';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import CobuConstants from '~/helpers/cobuConstants';
import styles from './index.module.css';
import Spacer from '~/components/Spacer/Spacer';
import { useHistory } from 'react-router-dom';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { ApiState, ViewState } from '~/types/common';
import {
  EntrataIntegratedBuilding,
  addNewEntrataIntegration,
  getEntrataIntegratedBuildings,
  getEntrataLeadList
} from '~/api/buildingEntrataIntegration';
import { buildingEntrataIntegrationTableColumns } from './entrataIntegrationsTableColumns';
import './style.css';
import GenericModal from '~/components/GenericModal/GenericModal';
import AddEntrataIntegrationForm, {
  AddNewEntrataIntegrationFormValues
} from './NewEntrataIntegrationForm';
import { useCobuSelector } from '~/hooks/useCobuSelector';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { Tabs } from '~/components/Tabs';
import {
  YardiBuildingIntegration,
  YardiProperty,
  addNewYardiIntegration,
  getYardiIntegratedBuildings,
  getYardiProperties,
  getYardiRentroll
} from '~/api/yardiIntegration';
import { yardiBuildingIntegrationTableColumns } from './yardiIntegrationsTableColumns';
import AddYardiIntegrationForm, {
  AddNewYardiIntegrationFormValues
} from './NewYardiIntegrationForm';
import { DeactivationLogUser, fetchPmsSyncDeactivationLogs } from '~/api/pms';
import CobuTable from '~/components/CobuTable/CobuTable';

type IntegrationTypes = 'entrata' | 'yardi';

const PmsIntegrationContainer = () => {
  const { loader } = useFullScreenLoader();
  const [getBuildingsApiState, setgetBuildingsApiState] =
    useState<ApiState>('LOADING');
  const [integrations, setIntegrations] = useState<EntrataIntegratedBuilding[]>(
    []
  );
  const [yardiIntegrations, setyardiIntegrations] = useState<
    YardiBuildingIntegration[]
  >([]);
  const [yardiProperties, setyardiProperties] = useState<YardiProperty[]>([]);
  const [addIntegrationViewState, setaddIntegrationViewState] =
    useState<ViewState>('HIDDEN');
  const [selectedIntegrationType, setselectedIntegrationType] =
    useState<IntegrationTypes>('entrata');
  const history = useHistory();
  const [deactivationLogs, setdeactivationLogs] = useState<
    DeactivationLogUser[] | undefined
  >();

  const { buildings } = useCobuSelector((state) => ({
    buildings: state.building.buildings
  }));

  /* UI Components */

  const downloadEntrataCsv = (buildingId: string) => {
    loader.show();
    getEntrataLeadList(buildingId).finally(() => {
      loader.hide();
    });
  };

  const downloadYardiCsv = (buildingId: string) => {
    loader.show();
    getYardiRentroll(buildingId).finally(() => loader.hide());
  };

  const getEntrataIntegrations = () => {
    loader.show();
    getEntrataIntegratedBuildings()
      .then((res) => {
        setgetBuildingsApiState('COMPLETED');
        setIntegrations(res.data);
      })
      .finally(loader.hide);
  };

  const getPropertyListFromYardi = () => {
    getYardiProperties()
      .then((res) => setyardiProperties(res.data.properties))
      .finally(loader.hide);
  };

  const getYardiIntegrations = () => {
    loader.show();
    getYardiIntegratedBuildings()
      .then((res) => {
        setgetBuildingsApiState('COMPLETED');
        setyardiIntegrations(res.data.integrations);
      })
      .finally(loader.hide);
  };

  const openAddIntegrationModal = () => setaddIntegrationViewState('VISIBLE');
  const closeAddIntegrationModal = () => setaddIntegrationViewState('HIDDEN');

  const onAddEntrataIntegrationClicked = (
    values: AddNewEntrataIntegrationFormValues
  ) => {
    loader.show();

    const newIntegration = new FormData();
    newIntegration.append('domain', values.domain);
    newIntegration.append('username', values.username);
    newIntegration.append('password', values.password);
    newIntegration.append('propertyId', values.propertyId as string);
    newIntegration.append('building', values.building as string);

    addNewEntrataIntegration(newIntegration)
      .then((res) => {
        closeAddIntegrationModal();
        getEntrataIntegrations();
        toast(res.data?.message);
      })
      .catch((e: any) => {
        const error = e as AxiosError;
        toast.error(
          error.response?.data?.message ||
            'Something went wrong. Please try again later'
        );
      })
      .finally(loader.hide);
  };

  const onAddYardiIntegrationClicked = (
    values: AddNewYardiIntegrationFormValues
  ) => {
    loader.show();

    const newIntegration = new FormData();
    newIntegration.append('yardiPropertyId', values.yardiPropertyId as string);
    newIntegration.append('building', values.building as string);

    addNewYardiIntegration(newIntegration)
      .then((res) => {
        closeAddIntegrationModal();
        getEntrataIntegrations();
        toast(res.data?.message);
      })
      .catch((e: any) => {
        const error = e as AxiosError;
        toast.error(
          error.message || 'Something went wrong. Please try again later'
        );
      })
      .finally(loader.hide);
  };

  const displayAddIntegrationForm = () => {
    let Component;

    switch (selectedIntegrationType) {
      case 'entrata': {
        Component = (
          <GenericModal
            title='Add Entrata Integration'
            showModal={addIntegrationViewState == 'VISIBLE'}
            onChange={closeAddIntegrationModal}
            content={
              <AddEntrataIntegrationForm
                buildings={buildings}
                onFormSubmit={onAddEntrataIntegrationClicked}
              />
            }
            hideActionButton
            canceActionButton={true}
          ></GenericModal>
        );
        break;
      }
      case 'yardi': {
        Component = (
          <GenericModal
            title='Add Yardi Integration'
            showModal={addIntegrationViewState == 'VISIBLE'}
            onChange={closeAddIntegrationModal}
            content={
              <AddYardiIntegrationForm
                buildings={buildings}
                onFormSubmit={onAddYardiIntegrationClicked}
                properties={yardiProperties}
              />
            }
            hideActionButton
            canceActionButton={true}
          ></GenericModal>
        );
        break;
      }
    }

    return Component;
  };

  const displayBuildingIntegrationByIntegrationType = () => {
    let Component;

    switch (selectedIntegrationType) {
      case 'yardi':
        Component = (
          <div className='building-integration-table'>
            <CobuTableAdvanced
              columns={yardiBuildingIntegrationTableColumns({
                downloadYardiCsv
              })}
              data={yardiIntegrations}
              noData={{ label: 'No Building Integration Found' }}
              // actions={[createPostAction()]}
            />
          </div>
        );
        break;
      case 'entrata':
        Component = (
          <div className='building-integration-table'>
            <CobuTableAdvanced
              columns={buildingEntrataIntegrationTableColumns({
                downloadEntrataCsv,
                getDeactivationLogs
              })}
              data={integrations}
              noData={{ label: 'No Building Integration Found' }}
              // actions={[createPostAction()]}
            />
          </div>
        );
        break;
    }

    return Component;
  };

  const getDeactivationLogs = (integrationId: string) => {
    loader.show();
    fetchPmsSyncDeactivationLogs(integrationId)
      .then((res) => {
        setdeactivationLogs(res.data?.details?.users);
      })
      .finally(() => loader.hide());
  };

  useEffect(() => {
    getEntrataIntegrations();
    // getYardiIntegrations();
    getPropertyListFromYardi();
  }, []);

  if (getBuildingsApiState == 'LOADING')
    return (
      <div className={styles.loadingContainer}>
        <h3 style={{ textAlign: 'center', marginTop: '30vh' }}>
          Loading PMS Integrations
        </h3>
      </div>
    );

  return (
    <div className='content-container'>
      <Breadcrumbs />
      <Spacer height={20} />
      {displayAddIntegrationForm()}
      {/* <div className='margin-bottom-16' style={{ width: '300px' }}>
        <Tabs.Container
          onChange={(value) =>
            setselectedIntegrationType(value as IntegrationTypes)
          }
          value={selectedIntegrationType}
        >
          <Tabs.Option
            selected={selectedIntegrationType == 'entrata'}
            key={'entrata'}
            value={'entrata'}
          >
            Entrata
          </Tabs.Option>
          <Tabs.Option
            selected={selectedIntegrationType == 'yardi'}
            key={'yardi'}
            value={'yardi'}
          >
            Yardi
          </Tabs.Option>
        </Tabs.Container>
      </div> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h1 className='text-capitalize'>
          {selectedIntegrationType} integrations
        </h1>
        <button
          className='btn btn-primary btn-small'
          onClick={openAddIntegrationModal}
        >
          Add New Integration
        </button>
      </div>
      <Spacer height={20} />
      {displayBuildingIntegrationByIntegrationType()}
      <GenericModal
        title={'Logs'}
        dismissOnTapOutside
        hideActionButton
        width='900px'
        onChange={() => setdeactivationLogs(undefined)}
        cancelOverride='Close'
        content={
          <div>
            <h4>Deactivated Users</h4>
            <CobuTableAdvanced
              data={deactivationLogs ?? []}
              columns={[
                { Header: 'UUID', accessor: 'uuid' },
                { Header: 'Name', accessor: 'email' },
                { Header: 'Phone', accessor: 'phone' }
              ]}
              noData={{ label: 'No deactivated users found' }}
            ></CobuTableAdvanced>
          </div>
        }
        showModal={!!deactivationLogs}
      ></GenericModal>
    </div>
  );
};

export default withFullScreenLoader(PmsIntegrationContainer);
