import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import classes from './index.module.css';
import { BuildingEntrataIntegration } from '~/types/buildingEntrataIntegration';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import {
  fetchBuildingEntrataIntegrationDetails,
  updateBuildingEntrataIntegration,
  getEntrataLeadList,
  addNewEntrataIntegration,
  deleteEntrataBuildingIntegration
} from '~/api/buildingEntrataIntegration';
import Spinner from '~/components/Spinner/Spinner';
import EntrataBuildingIntegrationForm from './EntrataIntegrationForm';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { ApiState } from '~/types/common';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import './style.css';
import { Tabs } from '~/components/Tabs';
import {
  UpdateYardiIntegrationPayload,
  YardiBuildingIntegration,
  YardiProperty,
  addNewYardiIntegration,
  deleteYardiBuildingIntegration,
  fetchYardiIntegrationForBuilding,
  getYardiIntegratedBuildings,
  getYardiProperties,
  getYardiRentroll,
  updateBuildingYardiIntegration
} from '~/api/yardiIntegration';
import YardiBuildingIntegrationForm from './YardiBuildingIntegrationForm';
import { ReactComponent as CopyIcon } from '~/utils/images/copyIcon.svg';

type IntegrationType = 'entrata' | 'yardi';

export const EditBuildingEntrataIntegration = () => {
  const { loader } = useFullScreenLoader();
  const history = useHistory();
  const { buildingId } = useParams<{ buildingId: string }>();
  const [selectedIntegrationType, setselectedIntegrationType] =
    useState<IntegrationType>('entrata');
  const [getIntegrationsApiState, setgetIntegrationsApiState] =
    useState<ApiState>('LOADING');
  const [entrataIntegrations, setentrataIntegrations] = useState<
    BuildingEntrataIntegration[]
  >([]);
  const [yardiIntegrations, setyardiIntegrations] = useState<
    UpdateYardiIntegrationPayload[]
  >([]);
  const [entrataIntegrationFrom, setentrataIntegrationFrom] =
    useState<BuildingEntrataIntegration>();
  const [yardiIntegrationFrom, setyardiIntegrationFrom] =
    useState<UpdateYardiIntegrationPayload>();
  const [entrataIntegrationFromVisible, setentrataIntegrationFromVisible] =
    useState<boolean>(false);
  const [yardiIntegrationFromVisible, setyardiIntegrationFromVisible] =
    useState<boolean>(false);

  const [yardiProperties, setyardiProperties] = useState<YardiProperty[]>([]);

  const getProperties = () => {
    getYardiProperties().then((res) => setyardiProperties(res.data.properties));
  };

  const canAddNewEntrataIntegration = () => {
    if (
      entrataIntegrationFromVisible &&
      entrataIntegrationFrom?.entrata_domain &&
      entrataIntegrationFrom?.entrata_password &&
      entrataIntegrationFrom?.entrata_property_id &&
      entrataIntegrationFrom?.entrata_username
    ) {
      return true;
    } else if (entrataIntegrationFromVisible) {
      throw Error('Incomplete or invalid form');
    } else {
      return false;
    }
  };

  const canAddNewYardiIntegration = () => {
    if (
      yardiIntegrationFromVisible &&
      yardiIntegrationFrom?.yardi_property_id
    ) {
      return true;
    } else if (yardiIntegrationFromVisible) {
      throw Error('Incomplete or invalid form');
    } else {
      return false;
    }
  };

  const addEntrataIntegration = () => {
    const newIntegration = new FormData();

    newIntegration.append(
      'domain',
      entrataIntegrationFrom?.entrata_domain as string
    );
    newIntegration.append(
      'username',
      entrataIntegrationFrom?.entrata_username as string
    );
    newIntegration.append(
      'password',
      entrataIntegrationFrom?.entrata_password as string
    );
    newIntegration.append(
      'propertyId',
      entrataIntegrationFrom?.entrata_property_id as string
    );
    newIntegration.append('building', buildingId);

    entrataIntegrationFrom?.leadSourceId &&
      newIntegration.append(
        'leadSourceId',
        entrataIntegrationFrom?.leadSourceId
      );

    typeof entrataIntegrationFrom?.default === 'boolean' &&
      newIntegration.append(
        'default',
        entrataIntegrationFrom?.default as unknown as string
      );

    return addNewEntrataIntegration(newIntegration)
      .then((res) => {
        toast.success('Added new integration');
      })
      .catch((e: any) => {
        const error = e as AxiosError;
        toast.error(
          error.message || 'Something went wrong. Please try again later'
        );
      })
      .finally(loader.hide);
  };

  const addYardiIntegration = () => {
    const newIntegration = new FormData();

    newIntegration.append(
      'yardiPropertyId',
      yardiIntegrationFrom?.yardi_property_id as string
    );
    newIntegration.append('building', buildingId);

    yardiIntegrationFrom?.leadSourceId &&
      newIntegration.append('leadSourceId', yardiIntegrationFrom?.leadSourceId);

    typeof yardiIntegrationFrom?.default === 'boolean' &&
      newIntegration.append(
        'default',
        yardiIntegrationFrom?.default as unknown as string
      );

    return addNewYardiIntegration(newIntegration)
      .then((res) => {
        toast.success('Added new integration');
      })
      .catch((e: any) => {
        const error = e as AxiosError;
        toast.error(
          error?.message || 'Something went wrong. Please try again later'
        );
      })
      .finally(loader.hide);
  };

  const saveEntrataChanges = () => {
    // building entrata integration

    try {
      let promises: Promise<any>[] = [];

      if (canAddNewEntrataIntegration()) {
        promises.push(addEntrataIntegration());
      }

      promises = [
        ...promises,
        ...entrataIntegrations.map((form) =>
          updateBuildingEntrataIntegration(form)
        )
      ];

      promises = promises.filter((promise) => !!promise);

      loader.show();

      Promise.all(promises)
        .then(() => {
          getEntrataIntegrations();
          getYardiIntegrations();
          getProperties();
          setentrataIntegrationFrom({
            entrata_domain: '',
            entrata_password: '',
            entrata_username: '',
            entrata_property_id: '',
            leadSourceId: '',
            default: false
          });
        })
        .finally(() => {
          loader.hide();
        });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const saveYardiChanges = () => {
    // building entrata integration

    try {
      let promises: Promise<any>[] = [];

      if (canAddNewYardiIntegration()) {
        promises.push(addYardiIntegration());
      }

      promises = [
        ...promises,
        ...yardiIntegrations.map((form) => updateBuildingYardiIntegration(form))
      ];

      promises = promises.filter((promise) => !!promise);

      loader.show();

      Promise.all(promises)
        .then(() => {
          getEntrataIntegrations();
          getYardiIntegrations();
          getProperties();
        })
        .finally(() => {
          loader.hide();
        });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const onSaveClicked = () => {
    if (selectedIntegrationType == 'entrata') {
      saveEntrataChanges();
    }

    if (selectedIntegrationType == 'yardi') {
      saveYardiChanges();
    }
  };

  const getEntrataIntegrations = () => {
    fetchBuildingEntrataIntegrationDetails(buildingId)
      .then((res) => {
        setentrataIntegrations(res.data);
        if (res.data.length == 0) {
          setLoaded(true);
        } else {
          setLoaded(false);
        }
      })
      .finally(() => {
        setgetIntegrationsApiState('COMPLETED');
        loader.hide();
      });
  };

  const getYardiIntegrations = () => {
    loader.show();
    getYardiIntegratedBuildings()
      .then((res) => {
        setyardiIntegrations(
          res.data.integrations
            .filter((integration) => integration.buildingId == buildingId)
            .map((integration) => ({
              yardi_property_id: integration.yardi_property_id,
              uuid: integration.uuid,
              leadSourceId: integration.leadSourceId,
              default: integration.default
            }))
        );
      })
      .finally(() => {
        setgetIntegrationsApiState('COMPLETED');
        loader.hide();
      });
  };

  useEffect(() => {
    loader.show();
    setgetIntegrationsApiState('LOADING');

    getEntrataIntegrations();
    getYardiIntegrations();
    getProperties();
  }, []);

  const [loaded, setLoaded] = useState(true);
  const [spinnerLoaded, setSpinner] = useState(false);

  const updateEntrataForm = (index: number, e: any) => {
    const clone = [...entrataIntegrations];
    const updated = clone[index];

    if (e.target.name === 'default') {
      for (let i = 0; i < clone.length; i++) {
        if (i !== index) {
          clone[i].default = false;
        }
      }
    }

    if (updated && e?.target?.name) {
      // @ts-ignore
      updated[e.target.name] = e.target.value;
      clone[index] = updated;
    }

    setentrataIntegrations(clone);
  };

  const updateYardiForm = (index: number, e: any) => {
    const clone = [...yardiIntegrations];
    const updated = clone[index];

    if (e.target.name === 'default') {
      for (let i = 0; i < clone.length; i++) {
        if (i !== index) {
          clone[i].default = false;
        }
      }
    }

    if (updated && e?.target?.name) {
      // @ts-ignore
      updated[e.target.name] = e.target.value;
      clone[index] = updated;
    }

    setyardiIntegrations(clone);
  };

  const updateNewForm = (e: any) => {
    const clone = { ...entrataIntegrationFrom };
    const updatedExistingIntegrations = [...entrataIntegrations];

    if (clone && e?.target?.name) {
      // @ts-ignore
      clone[e.target.name] = e.target.value;
    }

    if (e.target.name === 'default' && e.target.value) {
      for (let i = 0; i < updatedExistingIntegrations.length; i++) {
        updatedExistingIntegrations[i].default = false;
      }

      setentrataIntegrations(updatedExistingIntegrations);
    }

    setentrataIntegrationFrom(clone);
  };

  const updateNewYardiForm = (e: any) => {
    const clone = { ...yardiIntegrationFrom } as UpdateYardiIntegrationPayload;
    const updatedExistingIntegrations = [...yardiIntegrations];

    if (clone && e?.target?.name) {
      // @ts-ignore
      clone[e.target.name] = e.target.value;
    }

    if (e.target.name === 'default' && e.target.value) {
      for (let i = 0; i < updatedExistingIntegrations.length; i++) {
        updatedExistingIntegrations[i].default = false;
      }

      setyardiIntegrations(updatedExistingIntegrations);
    }

    setyardiIntegrationFrom(clone);
  };

  const deleteEntrataIntegration = (uuid: string) => {
    loader.show();

    deleteEntrataBuildingIntegration(uuid)
      .then(() => {
        getEntrataIntegrations();
      })
      .finally(loader.hide);
  };

  const deleteYardiIntegration = (uuid: string) => {
    loader.show();

    deleteYardiBuildingIntegration(uuid)
      .then(() => {
        getYardiIntegrations();
      })
      .finally(loader.hide);
  };

  const pullData = async (buildingId: string) => {
    loader.show();

    getEntrataLeadList(buildingId).then((response) => {
      loader.hide();
    });
  };

  const onDownloadYardiRentrollClicked = async () => {
    loader.show();

    getYardiRentroll(buildingId).then((response) => {
      loader.hide();
    });
  };

  const onChangeIntegrationType = (val: string) => {
    setselectedIntegrationType(val as IntegrationType);
    setentrataIntegrationFromVisible(false);
    setyardiIntegrationFromVisible(false);
    setentrataIntegrationFrom(undefined);
    setyardiIntegrationFrom(undefined);
  };

  const displayNewIntegrationForm = () => {
    if (selectedIntegrationType == 'entrata') {
      setentrataIntegrationFrom({
        entrata_domain: '',
        entrata_password: '',
        entrata_username: '',
        entrata_property_id: ''
      });
      setentrataIntegrationFromVisible(true);
    } else {
      setyardiIntegrationFrom({ yardi_property_id: '' });
      setyardiIntegrationFromVisible(true);
    }
  };

  return (
    <div className={`content-container`}>
      <Breadcrumbs />
      <h1 className={classes.title}>PMS Integration</h1>
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100px',
          cursor: 'pointer',
          gap: '10px 10px',
          maxHeight: '50px'
        }}
        onClick={() => {
          navigator.clipboard.writeText(
            `${window.location.origin}/entrata-config/${buildingId}`
          );

          toast('Link copied!');
        }}
      >
        <div>Entrata PMS config form link</div>
        <CopyIcon width={20} height={20} />
      </div>

      {/* <div
        className='margin-bottom-16 margin-top-16'
        style={{ width: '300px' }}
      >
        <Tabs.Container
          value={selectedIntegrationType}
          onChange={onChangeIntegrationType}
        >
          <Tabs.Option
            selected={selectedIntegrationType == 'entrata'}
            value='entrata'
          >
            Entrata
          </Tabs.Option>
          <Tabs.Option
            selected={selectedIntegrationType == 'yardi'}
            value='yardi'
          >
            Yardi
          </Tabs.Option>
        </Tabs.Container>
      </div> */}
      {selectedIntegrationType == 'entrata' && (
        <>
          <div>
            <div className={classes.gridContainer}>
              <div className={classes.item}>
                <h4>Actions</h4>
                {spinnerLoaded && <Spinner />}
                {!spinnerLoaded && (
                  <button
                    color='inherit'
                    onClick={() => pullData(buildingId)}
                    disabled={loaded}
                    type='button'
                  >
                    Download Entrata CSV
                  </button>
                )}
              </div>
            </div>
          </div>
          <div id='integration-form-wrapper'>
            {entrataIntegrations.map((form, index) => (
              <div>
                <div className='flex-row justify-between align-center'>
                  <h4 className='margin-top-16 color-warning'>
                    Integration #{index + 1}
                  </h4>
                  <button
                    style={{ width: '160px' }}
                    className='btn btn-ghost color-warning'
                    onClick={() => deleteEntrataIntegration(form.uuid!)}
                  >
                    DELETE
                  </button>
                </div>
                <EntrataBuildingIntegrationForm
                  onChange={(e) => updateEntrataForm(index, e)}
                  entrataIntegration={form}
                  index={index}
                />
              </div>
            ))}
          </div>
        </>
      )}
      {selectedIntegrationType == 'yardi' && (
        <>
          <div>
            <div className={classes.gridContainer}>
              <div className={classes.item}>
                <h4>Actions</h4>
                {spinnerLoaded && <Spinner />}
                {!spinnerLoaded && (
                  <button
                    color='inherit'
                    onClick={onDownloadYardiRentrollClicked}
                    disabled={loaded}
                    type='button'
                  >
                    Download Yardi CSV
                  </button>
                )}
              </div>
            </div>
          </div>
          <div id='integration-form-wrapper'>
            {yardiIntegrations.map((form, index) => (
              <div>
                <div className='flex-row justify-between align-center'>
                  <h4 className='margin-top-16 color-warning'>
                    Integration #{index + 1}
                  </h4>
                  <button
                    style={{ width: '160px' }}
                    className='btn btn-ghost color-warning'
                    onClick={() => deleteYardiIntegration(form.uuid!)}
                  >
                    DELETE
                  </button>
                </div>
                <YardiBuildingIntegrationForm
                  onChange={(e) => updateYardiForm(index, e)}
                  integration={form}
                  index={index}
                  properties={yardiProperties}
                />
              </div>
            ))}
          </div>
        </>
      )}
      {getIntegrationsApiState != 'LOADING' && (
        <div className='flex-row justify-center margin-top-24 margin-bottom-16'>
          <button
            disabled={entrataIntegrationFromVisible}
            className='btn-secondary btn-small'
            onClick={displayNewIntegrationForm}
          >
            Add New Integration
          </button>
        </div>
      )}
      {entrataIntegrationFrom && (
        <div className='margin-bottom-16'>
          <EntrataBuildingIntegrationForm
            index={(entrataIntegrations.length || 1) - 1}
            entrataIntegration={entrataIntegrationFrom}
            onChange={(e) => updateNewForm(e)}
          />
        </div>
      )}
      {yardiIntegrationFrom && (
        <div className='margin-bottom-16'>
          <YardiBuildingIntegrationForm
            index={(yardiIntegrations.length || 1) - 1}
            integration={yardiIntegrationFrom}
            onChange={(e) => updateNewYardiForm(e)}
            properties={yardiProperties}
          />
        </div>
      )}
      <div className={classes.formHeader}>
        <div className={classes.formButtons}>
          <button
            // disabled={isSubmitting || isSubmitSuccessful}
            className='btn-standard btn-primary margin-left-16 weight-500'
            type='submit'
            onClick={onSaveClicked}
          >
            Save
          </button>
          <button
            className='btn-standard btn-seconday weight-500'
            type='button'
            onClick={() => {
              history.push(`/building/${buildingId}`);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default withFullScreenLoader(EditBuildingEntrataIntegration);
