import { emperor } from '~/services';
import { BuildingEntrataIntegration } from '~/types/buildingEntrataIntegration';
import { convertKeyValueToFormData } from '~/helpers/helpers';
import { toast } from 'react-toastify';
import { YardiLeaseJsonToCSV } from '~/services/EntrataLeaseJsonToCSV';

export interface YardiBuildingIntegration {
  uuid: string;
  buildingId: string;
  buildingName: string;
  propertyId: string;
  yardi_property_id: string;
  ownerGroup: string;
  propertyOwnerGroup: string;
  lastSyncStatus: 'SUCCESS' | 'FAILED';
  lastSyncTime: Date;
  totalRecordsSynced: number;
  leadSourceId?: string;
  default: boolean;
}

export type UpdateYardiIntegrationPayload = {
  yardi_property_id: string;
  building?: string;
  uuid?: string;
  default?: boolean;
  leadSourceId?: string;
};

export type YardiProperty = {
  Code: string;
  MarketingName: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  City: string;
  State: string;
  PostalCode: number;
  AccountsReceivable: string;
  AccountsPayable: string;
  OperatingMonth: string;
};

export const getYardiProperties = async () => {
  return emperor.get<{ properties: YardiProperty[] }>('/yardi/properties');
};

export const getYardiIntegratedBuildings = async () => {
  return emperor.get<{ integrations: YardiBuildingIntegration[] }>(
    '/yardi/integrations'
  );
};

export const addNewYardiIntegration = async (payload: FormData) => {
  return emperor.put(`/yardi/integrations`, payload);
};

// BuildingEntrataIntegration
export const updateBuildingYardiIntegration = async (
  integration: UpdateYardiIntegrationPayload
) => {
  const data = convertKeyValueToFormData(integration);
  return emperor.patch(`yardibuildingintegration/${integration.uuid}`, data);
};

export const deleteYardiBuildingIntegration = async (integrationId: string) => {
  return emperor.delete(`yardibuildingintegration/${integrationId}`);
};

export const fetchYardiIntegrationForBuilding = async (buildingId: string) => {
  return emperor.get(`yardibuildingintegration?building=${buildingId}`);
};

export const getYardiRentroll = async (buildingId: string) => {
  const response = await emperor.get(`/yardi/rentroll/${buildingId}`);

  if (response.status === 200) {
    let lease: any[] = [];

    const buildingName = response?.data?.building.name;
    const buildingLink = response?.data?.building.link;
    const buildingCode = response?.data?.building.code;

    const csvStr = YardiLeaseJsonToCSV(
      response?.data?.residents,
      buildingName,
      buildingId,
      buildingLink,
      buildingCode
    );

    // download CSV
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${buildingName}.csv`;
    hiddenElement.click();
  } else {
    toast('There was an error trying to download the entrata data!');
  }
};
