import React from 'react';
import { Field, Formik, FormikProps } from 'formik';
import { Building } from '~/types/building';
import { Group } from '~/reducers/groupAdmin';
import { Colors } from '~/enums/Colors';
import * as Yup from 'yup';
import './style.css';
import { YardiProperty } from '~/api/yardiIntegration';

export interface AddNewYardiIntegrationFormValues {
  building: string;
  yardiPropertyId: string;
}

const validationSchema = Yup.object().shape({
  building: Yup.string().required('Building is missing or is invalid'),
  yardiPropertyId: Yup.string().required(
    '̦Yardi Property ID is missing or is invalid'
  )
});

export interface AddNewYardiIntegrationFormValuesErrors
  extends AddNewYardiIntegrationFormValues {}

type AddYardiIntegrationFormProps = {
  buildings: Building[];
  onFormSubmit: (values: AddNewYardiIntegrationFormValues) => void;
  properties: YardiProperty[];
};

const AddYardiIntegrationForm = (props: AddYardiIntegrationFormProps) => {
  const { onFormSubmit, properties } = props;

  const buildings = Object.values(props.buildings);

  return (
    <Formik
      initialValues={{
        building: '',
        yardiPropertyId: ''
      }}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {(formikProps: FormikProps<AddNewYardiIntegrationFormValues>) => {
        const {
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          handleChange
        } = formikProps;

        return (
          <form className='group-form' onSubmit={handleSubmit}>
            <div>
              <div className='margin-top-16'>
                <p id='building-label'>Building</p>
                <select
                  name='building'
                  value={values.building}
                  onChange={handleChange}
                  className='margin-top-16'
                  style={{ width: '100%' }}
                >
                  <option value='' disabled>
                    Select a Building
                  </option>
                  {buildings.map((building) => (
                    <option value={building.uuid} key={building.uuid}>
                      {building.name}
                    </option>
                  ))}
                </select>
                <p className='margin-top-16' id='building-label'>
                  Property on Yardi
                </p>
                <select
                  name='yardiPropertyId'
                  value={values.yardiPropertyId}
                  onChange={handleChange}
                  className='margin-top-8'
                  style={{ width: '100%' }}
                >
                  <option value='' disabled>
                    Select a active property
                  </option>
                  {properties.map((property) => (
                    <option value={property.Code} key={property.Code}>
                      {property.MarketingName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div id='buttons-wrapper'>
              <button
                type='submit'
                className='btn-standard btn-secondary margin-right-16'
              >
                Cancel
              </button>
              <button type='submit' className='btn-standard btn-primary'>
                Save
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddYardiIntegrationForm;
