import moment from 'moment';

function shortDateTimeFormat(dateTime: string, timezone: string) {
  const dateInTimezone = moment(dateTime).tz(timezone);
  return (
    dateInTimezone.format('ddd MMM DD').toString().toUpperCase() +
    ' ' +
    dateInTimezone.format('h:mm A')
  );
}

function longDateTimeFormat(dateTime: string, timezone: string) {
  const dateInTimezone = moment(dateTime).tz(timezone);
  return (
    dateInTimezone.format('ddd').toString().toUpperCase() +
    ', ' +
    dateInTimezone.format('MMMM DD') +
    ' at ' +
    dateInTimezone.format('h:mm A')
  );
}

function shortDateTimeFormatAlternate(dateTime: string) {
  return `${moment
    .parseZone(dateTime)
    .format('MM/DD/YYYY')} at ${moment.parseZone(dateTime).format('h:mm A')} `;
}

function calendarDateFormat(dateTime: string) {
  return moment(dateTime).format('YYYY-MM-DD');
}
export {
  shortDateTimeFormat,
  longDateTimeFormat,
  shortDateTimeFormatAlternate,
  calendarDateFormat
};
