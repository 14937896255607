import React from 'react';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  IReferralFormData,
  getReferralFormData,
  postReferralLead
} from '~/api/referral';
import { IReferralLeadFormData } from '~/containers/Referral/interfaces';
import { IMultiSelectOptionList } from '~/helpers/interfaces';
import { fetchBuildingDashboardConfig } from '~/api/building';
import { BuildingDashboardConfigResponse } from '~/types/building';
import { Mixpanel } from '~/services/MixpanelService';

export default (referralCode: string, isAppReferral: boolean) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [referraFormlData, setReferralFormData] =
    useState<IReferralFormData | null>(null);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showTermAndCondition, setShowTermAndCondition] = useState(false);
  const [buildingConfig, setBuildingConfig] =
    useState<BuildingDashboardConfigResponse | null>(null);

  const termsConditionOption: IMultiSelectOptionList[] = [
    {
      value: 't&c',
      text: '',
      showSibling: true,
      isSelected: false,
      sibling: (
        <div
          style={{ color: 'blue', textDecoration: 'underline' }}
          onClick={() => setShowTermAndCondition(true)}
        >
          {'I agree to the Terms & Conditions'}
        </div>
      ),
      containerStyle: {
        display: 'flex',
        flexDirection: 'row',
        margin: '8px 0 0 0'
      }
    }
  ];

  const confirmationOption: IMultiSelectOptionList[] = [
    {
      value: 'confirmationEmail',
      text: '',
      sibling: (
        <div>
          {'I agree to receive an email confirmation of this form submission.'}
        </div>
      ),
      showSibling: true,
      isSelected: false,
      containerStyle: {
        display: 'flex',
        flexDirection: 'row',
        margin: '8px 0 0 0'
      }
    }
  ];

  const [termsAndConditionOption, setTermsAndConditionOption] = useState(() =>
    cloneDeep(termsConditionOption)
  );

  const [emailConfirmationOption, setEmailConfirmationOption] = useState(() =>
    cloneDeep(confirmationOption)
  );

  const fetchReferralFormData = async () => {
    try {
      setLoader(true);
      const res = await getReferralFormData(referralCode);

      setReferralFormData(res.data);

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const fetchBuildingConfig = async () => {
    try {
      const res = await fetchBuildingDashboardConfig(
        referraFormlData?.building.uuid as string
      );

      setBuildingConfig(res.data);
    } catch (e) {}
  };

  useEffect(() => {
    if (referraFormlData) {
      Mixpanel.track('referral_form_mounted', {
        buildingId: referraFormlData?.building.uuid,
        buildingName: referraFormlData?.building.name,
        referrerId: referraFormlData?.referredUser.uuid,
        referralOfferId: referraFormlData?.referralOffer.uuid,
        isAppReferral
      });

      fetchBuildingConfig();
    }
  }, [referraFormlData]);

  useEffect(() => {
    fetchReferralFormData();
  }, []);

  const onSubmit = async (
    {
      firstName,
      lastName,
      phoneNumber,
      email,
      confirmationEmail
    }: IReferralLeadFormData,
    callback?: () => void
  ) => {
    try {
      setLoader(true);

      await postReferralLead({
        firstName,
        lastName,
        phoneNumber,
        email,
        confirmationEmail: confirmationEmail || false,
        leadReferredBy: referraFormlData?.referredUser.uuid as string,
        referral_offer: referraFormlData?.referralOffer.uuid as string,
        buildingId: referraFormlData?.building.uuid as string
      });

      if (callback) {
        callback();
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const onShowThankYou = () => {
    setShowThankYou(true);
  };

  const submitAnother = () => {
    setShowThankYou(false);
  };

  return {
    loader,
    onSubmit,
    referraFormlData,
    showThankYou,
    onShowThankYou,
    submitAnother,
    termsAndConditionOption,
    setTermsAndConditionOption,
    emailConfirmationOption,
    setEmailConfirmationOption,
    showTermAndCondition,
    setShowTermAndCondition,
    buildingConfig
  };
};
